import React from "react";

const CreatePostHeader = ({ currentStep, dictionary }) => (
  <div className="forum-create-post__tabs-container">
    <div className="forum-create-post__tabs-inner">
      <div className="forum-create-post__tabs">
        <div
          className={`forum-create-post__tab ${
            currentStep === 1 ? "tab--active" : ""
          }`}
        >
          <span>{dictionary.Labels.StepOneTitle}</span>
        </div>
        <div
          className={`forum-create-post__tab ${
            currentStep === 2 ? "tab--active" : ""
          }`}
        >
          <span>{dictionary.Labels.StepTwoTitle}</span>
        </div>
      </div>
    </div>
  </div>
);

export default CreatePostHeader;
