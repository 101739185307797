import { store, useGlobalState } from "state-pool";
import * as helpers from "../shared/helpers";
import React from "react";
import { useHistory } from "react-router-dom";

export default function SingleFacetedFilter(props) {
  const history = useHistory();
  const $ = window.$;
  const [facetedFilters, setFacetedFilters] = useGlobalState("facetedFilters");
  const [detailedMemberList, setDetailedMemberList, updateDetailedMemberList] =
    useGlobalState("detailedMemberList");
  const [appdata, setAppData] = useGlobalState("appdata");

  //please don't remove variables below
  const [memberFilter, setMemberFilter, updateMemberFilter] =
    useGlobalState("memberFilter");
  const [committeeFilter, setCommitteeFilter, updateCommitteeFilter] =
    useGlobalState("committeeFilter");
  const [constituencyFilter, setConstituencyFilter, updateConstituencyFilter] =
    useGlobalState("constituencyFilter");
  const [
    ministerialTitleFilter,
    setMinisterialTitleFilter,
    updateMinisterialTitleFilter,
  ] = useGlobalState("ministerialTitleFilter");
  const [
    rapporteurshipFilter,
    setRapporteurshipFilter,
    updateRapporteurshipFilter,
  ] = useGlobalState("rapporteurshipFilter");
  //please don't remove variables above

  function reomveFilter(index, filterItem) {
    let newFacetedFilters = [...facetedFilters]; // copying the old facetedFilters array
    newFacetedFilters[index].value = 0;
    newFacetedFilters[index].name = [];
    newFacetedFilters[index][Object.keys(newFacetedFilters[index])[0]] = null;
    setFacetedFilters(newFacetedFilters);

    history.push({
      search: helpers.updateUrlParameter(
        history.location.search,
        props.filter.filterName,
        0
      ),
    });

    eval(filterItem)((filter) => {
      filter.value = 0;
    });
    $("#" + props.filter.id)
      .val(0)
      .trigger("chosen:updated");
    setDetailedMemberList([]);
  }

  if (props.filter.value != 0) {
    return (
      <div className="faceted-filter__item">
        <span>
          {props.filter.name[0] !== undefined ||
          props.filter.name[1] !== undefined
            ? props.filter.name[0].personId
              ? props.filter.name[0][Object.keys(props.filter.name[0])[0]]
              : props.filter.name[0][Object.keys(props.filter.name[0])[1]] !==
                  undefined &&
                typeof props.filter.name[0][
                  Object.keys(props.filter.name[0])[1]
                ] === "string"
              ? props.filter.name[0][Object.keys(props.filter.name[0])[1]]
              : props.filter.name[0][Object.keys(props.filter.name[0])[0]]
            : ""}
          {facetedFilters.filter((item) => item.isInMinisters)[0] !==
            undefined &&
            facetedFilters.filter((item) => item.isInMinisters)[0]
              .isInMinisters &&
            appdata.ministerialTitles.allSeelction}
        </span>{" "}
        <a
          role="button"
          className="icon-ft_accordionplus faceted-filter__item--remove"
          onClick={() => reomveFilter(props.index, props.filter.updateFilter)}
        >
          <span className="visually-hidden ">
            Fjern{" "}
            {props.filter.name[0] !== undefined ||
            props.filter.name[1] !== undefined
              ? props.filter.name[0].personId
                ? props.filter.name[0][Object.keys(props.filter.name[0])[0]]
                : props.filter.name[0][Object.keys(props.filter.name[0])[1]] !==
                    undefined &&
                  typeof props.filter.name[0][
                    Object.keys(props.filter.name[0])[1]
                  ] === "string"
                ? props.filter.name[0][Object.keys(props.filter.name[0])[1]]
                : props.filter.name[0][Object.keys(props.filter.name[0])[0]]
              : ""}
          </span>
        </a>
      </div>
    );
  } else {
    return <></>;
  }
}
