import { useHistory } from "react-router";
import { useGlobalState } from "state-pool";
import * as helpers from "../shared/helpers";

export default function Reset(props) {
  const [appdata] = useGlobalState("appdata");
  const otherLabel = appdata.otherLabels.otherLabel;
  const [chosenParties, setChosenParties, updateChosenParties] =
    useGlobalState("chosenParties");
  const [detailedMemberList, setDetailedMemberList, updateDetailedMemberList] =
    useGlobalState("detailedMemberList");
  const [
    dataVisualizationDetailedMemberList,
    setDataVisualizationDetailedMemberList,
  ] = useGlobalState("dataVisualizationDetailedMemberList");
  const [chosenMember, setChosenMember] = useGlobalState("chosenMember");
  const [memberFilter, setMemberFilter, updateMemberFilter] =
    useGlobalState("memberFilter");
  const [committeeFilter, setCommitteeFilter, updateCommitteeFilter] =
    useGlobalState("committeeFilter");
  const [constituencyFilter, setConstituencyFilter, updateConstituencyFilter] =
    useGlobalState("constituencyFilter");
  const [
    ministerialTitleFilter,
    setMinisterialTitleFilter,
    updateMinisterialTitleFilter,
  ] = useGlobalState("ministerialTitleFilter");
  const [
    rapporteurshipFilter,
    setRapporteurshipFilter,
    updateRapporteurshipFilter,
  ] = useGlobalState("rapporteurshipFilter");
  const [facetedFilters, setFacetedFilters] = useGlobalState("facetedFilters");
  const [date] = useGlobalState("date");

  const $ = window.$;
  const history = useHistory();

  function reset() {
    setChosenParties([]);
    setChosenMember(null);
    setDataVisualizationDetailedMemberList([]);
    facetedFilters.forEach((element) => {
      element.name = [];
    });
    setFacetedFilters([]);

    setDetailedMemberList([]);

    // Update all chosen selects
    var labels = getDropdownLabels();
    labels.forEach(updateDropdowns);
    updateFilters();

    history.push({
      search: helpers.updateUrlParameter("", "date", date),
    });

    function getDropdownLabels() {
      const labels = [];
      const {
        members,
        committees,
        constituencies,
        ministerialTitles,
        spokesmen,
      } = props.data;

      if (members !== undefined) {
        labels.push(members.label);
      }
      if (committees !== undefined) {
        labels.push(committees.label);
      }
      if (constituencies !== undefined) {
        labels.push(constituencies.label);
      }
      if (ministerialTitles !== undefined) {
        labels.push(ministerialTitles.label);
      }
      if (spokesmen !== undefined) {
        labels.push(spokesmen.label);
      }

      return labels;
    }

    function updateDropdowns(label) {
      $("#" + label)
        .val(0)
        .trigger("chosen:updated");
    }

    function updateFilters() {
      updateMemberFilter((filter) => {
        filter.value = 0;
      });

      updateCommitteeFilter((filter) => {
        filter.value = 0;
      });

      updateConstituencyFilter((filter) => {
        filter.value = 0;
      });

      updateMinisterialTitleFilter((filter) => {
        filter.value = 0;
      });

      updateRapporteurshipFilter((filter) => {
        filter.value = 0;
      });
    }
  }
  if (
    chosenParties.length > 0 ||
    detailedMemberList.length > 0 ||
    chosenMember ||
    parseInt(memberFilter.value) !== 0 ||
    parseInt(committeeFilter.value) !== 0 ||
    parseInt(constituencyFilter.value) !== 0 ||
    parseInt(ministerialTitleFilter.value) !== 0 ||
    parseInt(rapporteurshipFilter.value) !== 0
  ) {
    return (
      <div>
        <a
          role="button"
          tabIndex="0"
          className="reset-btn"
          onKeyPress={reset}
          onClick={reset}
        >
          {otherLabel.find(element => element.id === "reset").label}
        </a>
      </div>
    );
  } else {
    return <></>;
  }
}
