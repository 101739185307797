import MemberPopup from "../components/MemberPopup.jsx";
import { useGlobalState } from "state-pool";
import { useEffect } from "react";
import * as helpers from "../shared/helpers";
import Loading from "../components/Loading";
import { useHistory } from "react-router-dom";

export default function ChartAge(props) {
  const [date] = useGlobalState("date");
  const [appdata] = useGlobalState("appdata");
  const history = useHistory();
  const [detailedMemberList] = useGlobalState("detailedMemberList");
  const [chosenParties] = useGlobalState("chosenParties");

  useEffect(() => {
    history.push({
      search: helpers.updateUrlParameter(history.location.search, "date", date),
    });
  }, []);

  useEffect(() => {
    helpers.updateChartColumns();
  });

  if (appdata === null) {
    return (
      <div className="container">
        <Loading />
      </div>
    );
  } else if (appdata) {
    return (
      <div className="charts charts-age">
        {props.groupdata.group.map((group) => (
          <div className="charts__chart" key={group.label}>
            <div className="charts__chart-group">
              {appdata.parties.party.map((party) => (
                <div
                  className="chart__party-group"
                  key={group.label + "-" + party.partyAbbreviation}
                >
                  {appdata.seats.seat.map(
                    (seat, i) =>
                      seat.partyAbbreviation === party.partyAbbreviation &&
                      seat.member === "1" &&
                      parseInt(seat.age) >= parseInt(group.min) &&
                      parseInt(seat.age) <= parseInt(group.max) && (
                        <MemberPopup
                          key={i + "-" + seat.fullName}
                          isClickable={true}
                          member={seat}
                          useFilters={false}
                        />
                      )
                  )}
                </div>
              ))}
            </div>
            <div className="chart__label">
              {group.label}
              <div className="chart__count">
                <span className="chart__count-number print-version__hide">
                  {chosenParties.length === 0 &&
                    appdata.seats.seat.filter(
                      (seat) =>
                        parseInt(seat.age) >= parseInt(group.min) &&
                        parseInt(seat.age) <= parseInt(group.max) &&
                        seat.member === "1"
                    ).length}
                </span>
                <span className="chart__count-number print-version__show">
                  {
                    appdata.seats.seat.filter(
                      (seat) =>
                        parseInt(seat.age) >= parseInt(group.min) &&
                        parseInt(seat.age) <= parseInt(group.max) &&
                        seat.member === "1"
                    ).length
                  }
                </span>
                <span className="chart__count-number print-version__hide">
                  {chosenParties.length > 0 &&
                    (detailedMemberList.filter(
                      (seat) =>
                        parseInt(seat.age) >= parseInt(group.min) &&
                        parseInt(seat.age) <= parseInt(group.max) &&
                        seat.member === "1"
                    ).length > 0
                      ? detailedMemberList.filter(
                          (seat) =>
                            parseInt(seat.age) >= parseInt(group.min) &&
                            parseInt(seat.age) <= parseInt(group.max) &&
                            seat.member === "1"
                        ).length
                      : "")}
                </span>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  }
}
