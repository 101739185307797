'use strict';

/*
Copyright Captcha, Inc. 2004-2021. All rights reserved.

BotDetect, BotDetect CAPTCHA, Lanap, Lanap CAPTCHA, Lanap BotDetect, 
Lanap BotDetect CAPTCHA, Lanapsoft, Lanapsoft CAPTCHA, 
Lanapsoft BotDetect, Lanapsoft BotDetect CAPTCHA, and Lanap Software 
are trademarks of Captcha, Inc. All other product, brand, and company 
names are mentioned for identification purposes only and are trademarks 
or registered trademarks of their respective owners.

Captcha, Inc. - formerly: Lanapsoft, Inc. / Lanap, Inc.
*/

var captchaSettings = {
  save: function save(captchaFormId, settings) {
    localStorage.setItem('captchasettings_' + captchaFormId, JSON.stringify(settings));
  },

  load: function load(captchaFormId) {
    var settings = null;
    var saved = localStorage.getItem('captchasettings_' + captchaFormId);
    if (saved) {
      settings = JSON.parse(saved);
    }
    return settings;
  }
};

module.exports = captchaSettings;