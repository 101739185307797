import React from "react";
import { useState, useEffect } from "react";
import { useHistory } from "react-router";
import * as helpers from "../shared/helpers";
import { useGlobalState } from "state-pool";

export default function Parties(props) {
  const [chosenParty, setChosenParty, updateChosenParty] =
    useGlobalState("chosenParty");
  const [chosenParties, setChosenParties, updateChosenParties] =
    useGlobalState("chosenParties");
  const [detailedMemberList, setDetailedMemberList, updateDetailedMemberList] =
    useGlobalState("detailedMemberList");
  const [
    dataVisualizationDetailedMemberList,
    setDataVisualizationDetailedMemberList,
  ] = useGlobalState("dataVisualizationDetailedMemberList");
  const [appdata, setAppData, updateAppdata] = useGlobalState("appdata");
  const [chosenMember, setChosenMember] = useGlobalState("chosenMember");
  const history = useHistory();
  const [facetedFilters, setFacetedFilters] = useGlobalState("facetedFilters");
  const [date] = useGlobalState("date");
  const [memberFilter, setMemberFilter, updateMemberFilter] =
    useGlobalState("memberFilter");
  const [committeeFilter, setCommitteeFilter, updateCommitteeFilter] =
    useGlobalState("committeeFilter");
  const [constituencyFilter, setConstituencyFilter, updateConstituencyFilter] =
    useGlobalState("constituencyFilter");
  const [
    ministerialTitleFilter,
    setMinisterialTitleFilter,
    updateMinisterialTitleFilter,
  ] = useGlobalState("ministerialTitleFilter");
  const [
    rapporteurshipFilter,
    setRapporteurshipFilter,
    updateRapporteurshipFilter,
  ] = useGlobalState("rapporteurshipFilter");
  const [isAllMinister, setIsAllMinister] = useGlobalState("isAllMinister");

  const $ = window.$;

  function reset() {
    setChosenParties([]);
    setDetailedMemberList([]);
    setChosenMember(null);
    setFacetedFilters([]);
    setIsAllMinister(false);
    setDataVisualizationDetailedMemberList([]);

    // Update all chosen selects
    updateMemberFilter((filter) => {
      filter.value = 0;
    });

    $("#Medlemmer").val(0).trigger("chosen:updated");

    updateCommitteeFilter((filter) => {
      filter.value = 0;
    });

    $("#Udvalg").val(0).trigger("chosen:updated");
    updateConstituencyFilter((filter) => {
      filter.value = 0;
    });
    $("#Valgkreds").val(0).trigger("chosen:updated");

    updateMinisterialTitleFilter((filter) => {
      filter.value = 0;
    });
    $("#Regeringen").val(0).trigger("chosen:updated");

    updateRapporteurshipFilter((filter) => {
      filter.value = 0;
    });
    $("#Ordførerskab").val(0).trigger("chosen:updated");

    history.push({
      search: helpers.updateUrlParameter("", "date", date),
    });
  }
  function handleClick(party, index) {
    if (chosenParties.length > 0) {
      if (chosenParties.filter((chosenParty) => chosenParty === party)[0]) {
        setChosenParties(chosenParties.filter((item) => item !== party));
        history.push({
          search: helpers.updateUrlParameter(
            history.location.search,
            "partyFilter_" + index,
            ""
          ),
        });
        setDetailedMemberList(
          detailedMemberList.filter((item) => item.partyAbbreviation !== party)
        );

        if (
          chosenMember &&
          party ===
            detailedMemberList
              .filter((item) => item.seatNumber === chosenMember)
              .map((item) => item.partyAbbreviation)[0]
        ) {
          setChosenMember(null);
          setDataVisualizationDetailedMemberList([]);
        }
      } else {
        const membersToPush = appdata.seats.seat.filter(
          (seat) => seat.partyAbbreviation === party
        );
        setDetailedMemberList([...detailedMemberList, ...membersToPush]);

        setChosenParties([...chosenParties, party]);
        history.push({
          search: helpers.updateUrlParameter(
            history.location.search,
            "partyFilter_" + index,
            party
          ),
        });
      }
    } else {
      reset();
      setChosenParties([...chosenParties, party]);
      history.push({
        search: helpers.updateUrlParameter(
          history.location.search,
          "partyFilter_" + index,
          party
        ),
      });
      const membersToPush = appdata.seats.seat.filter(
        (seat) => seat.partyAbbreviation === party
      );
      setDetailedMemberList(membersToPush);
    }
  }

  return (
    <div className="parties">
      {props.parties.map((item, index) => (
        <div
          key={index}
          className="col-xs-2 col-md-12 parties__item--container"
        >
          <div
            tabIndex="0"
            role="button"
            key={index}
            className={`parties__item ${
              chosenParties.filter(
                (chosenParty) => chosenParty === item.partyAbbreviation
              )[0] && "set-highlight"
            }`}
            aria-selected={
              chosenParties.filter(
                (chosenParty) => chosenParty === item.partyAbbreviation
              )[0]
                ? "true"
                : "false"
            }
            onKeyPress={() => handleClick(item.partyAbbreviation, index)}
            onClick={() => handleClick(item.partyAbbreviation, index)}
          >
            <span
              className="parties__dot"
              style={{
                backgroundColor: item.dotColor,
                borderColor: item.strokeColor,
              }}
            ></span>
            <span className="parties__short-name">
              {item.partyAbbreviation}
            </span>
            <span
              className="parties__full-name"
              style={{ textDecorationColor: item.strokeColor }}
            >
              {item.partyName}
            </span>
          </div>
        </div>
      ))}
      <div
        className={`parties__overlay ${props.isHighlightParty ? "active" : ""}`}
      ></div>
    </div>
  );
}
