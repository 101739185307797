'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Captcha = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _captchaSettings = require('./captcha-settings');

var _captchaSettings2 = _interopRequireDefault(_captchaSettings);

var _javascriptCaptcha = require('javascript-captcha/dist/esm/javascript-captcha');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; } /*
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                               Copyright Captcha, Inc. 2004-2021. All rights reserved.
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                               
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                               BotDetect, BotDetect CAPTCHA, Lanap, Lanap CAPTCHA, Lanap BotDetect, 
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                               Lanap BotDetect CAPTCHA, Lanapsoft, Lanapsoft CAPTCHA, 
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                               Lanapsoft BotDetect, Lanapsoft BotDetect CAPTCHA, and Lanap Software 
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                               are trademarks of Captcha, Inc. All other product, brand, and company 
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                               names are mentioned for identification purposes only and are trademarks 
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                               or registered trademarks of their respective owners.
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                               
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                               Captcha, Inc. - formerly: Lanapsoft, Inc. / Lanap, Inc.
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                               */

var Captcha = exports.Captcha = function (_React$Component) {
  _inherits(Captcha, _React$Component);

  function Captcha(props) {
    _classCallCheck(this, Captcha);

    var _this = _possibleConstructorReturn(this, (Captcha.__proto__ || Object.getPrototypeOf(Captcha)).call(this, props));

    _this.captchaTagId = 'captcha_tag_' + _this.props.captchaFormId;
    return _this;
  }

  _createClass(Captcha, [{
    key: 'componentDidMount',


    // generate captcha html markup in view
    value: function componentDidMount() {
      var captchaFormId = this.props.captchaFormId;
      var settings = _captchaSettings2.default.load(captchaFormId);
      if (settings) {
        var captcha = (0, _javascriptCaptcha.createCaptchaInstance)(settings);
        captcha.integrationType = '203';
        this.displayCaptcha(captcha, captchaFormId);
      } else {
        throw new Error('Could not find captcha settings in data storage: ' + captchaFormId);
      }
    }
  }, {
    key: 'displayCaptcha',
    value: function displayCaptcha(captcha, captchaFormId) {
      var captchaTag = document.getElementById('captcha_tag_' + captchaFormId);
      captcha.getHtml(function (captchaHtml) {
        captchaTag.innerHTML = captchaHtml;
      }, function (errorMessage) {
        captchaTag.innerHTML = errorMessage;
      });
    }
  }, {
    key: 'render',
    value: function render() {
      return _react2.default.createElement('div', { id: this.captchaTagId });
    }
  }], [{
    key: 'createCaptchaInstance',
    value: function createCaptchaInstance(settings) {
      if (!(settings && settings.captchaFormId && settings.captchaEndpointUrl)) {
        var errorMessage = 'captchaFormId and captchaEndpointUrl properties are not set!\n    The React Captcha Component requires the "captchaFormId" and "captchaEndpointUrl" properties to be set.\n    For example: \n    constructor(): {\n      this.captcha = Captcha.createCaptchaInstance({\n        captchaFormId: \'your_form\',\n        captchaEndpointUrl: \'https://your-app-backend-hostname.your-domain.com/simple-captcha-endpoint-url\'\n      });\n    }\n      ';
        throw new Error(errorMessage);
      }

      var captcha = (0, _javascriptCaptcha.createCaptchaInstance)(settings);
      _captchaSettings2.default.save(settings.captchaFormId, settings);

      return captcha;
    }
  }]);

  return Captcha;
}(_react2.default.Component);