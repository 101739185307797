import React, { useState } from "react";

import ImageCropper from "../image-cropper/image-cropper.component";
import Dropzone from "../dropzone/dropzone.component";

import "react-image-crop/dist/ReactCrop.css";

const ModalImageUpload = (props) => {
  const {
    closeModal,
    handleImageChange,
    imgSrc,
    setCroppedImage,
    setImgSrc,
    categories,
    watch,
    setFormValue,
    dictionary,
    imageAccept,
    imageSrcError,
    errors,
  } = props;

  const [isUploadActive, setIsUploadActive] = useState("uploadActive");
  const [activeGalleryImage, setActiveGalleryImage] = useState({ index: "" });
  const watchCategory = watch("category");

  const getCurrentSelectedGalley = () => {
    const category = categories.find((x) => x.ID === watchCategory);
    return typeof category !== "undefined" ? category.GalleryImages : [];
  };

  const handleSaveGalleryImage = (event) => {
    event.preventDefault();
    getBase64Image(activeGalleryImage.element);
    setFormValue("fileName", activeGalleryImage.image.Name);
    setFormValue("fileType", activeGalleryImage.image.MimeType);
    closeModal();
  };

  const getBase64Image = (img) => {
    try {
      const canvas = document.createElement("canvas");
      canvas.width = 1040;
      canvas.height = 585;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
      const dataURL = canvas.toDataURL("image/jpg", 1);
      setCroppedImage(dataURL);
    } catch (e) {}
  };

  return (
    <div className="file-modal__image">
      <div className="file-modal__header">
        <h2>{dictionary.Modal.Title}</h2>
        <button
          type="button"
          className="close-modal"
          onClick={closeModal}
          aria-label={dictionary.Modal.CloseModal}
        ></button>
      </div>
      <div className="file-modal__content">
        <div
          className={`file-modal__gallery-tab-content ${
            isUploadActive === "galleryActive" ? "tab--active" : ""
          }`}
        >
          <button
            type="button"
            className="file-modal__gallery-tab"
            aria-label={dictionary.Modal.GalleryImageTab}
            onClick={(event) => {
              event.preventDefault();
              if (event.keyCode === 13 || event.keyCode === 32) {
                setIsUploadActive("galleryActive");
              }
              setIsUploadActive("galleryActive");
            }}
          >
            {dictionary.Modal.GalleryImageTab}
          </button>
          {isUploadActive === "galleryActive" && (
            <div className="file-modal__file-gallery file-modal__container">
              <div className="file-modal__file-gallery-images">
                {getCurrentSelectedGalley().map((image, index) => {
                  return (
                    <button
                      type="button"
                      name={image.Alt}
                      key={index}
                      className={`gallery-image ${
                        index === activeGalleryImage.index
                          ? "active-gallery-image"
                          : "inactive-gallery-image"
                      }`}
                      onKeyDown={(event) => {
                        if (event.keyCode === 13 || event.keyCode === 32) {
                          setActiveGalleryImage({
                            element: event.target.querySelector("img"),
                            index,
                            image,
                          });
                        }
                      }}
                    >
                      <img
                        src={image.Url}
                        alt={image.Alt}
                        onClick={(event) => {
                          event.preventDefault();
                          setActiveGalleryImage({
                            element: event.target,
                            index,
                            image,
                          });
                        }}
                      />
                    </button>
                  );
                })}
              </div>
              <div
                className="file-modal__image-crop-buttons"
                onClick={(event) => {
                  event.preventDefault();
                  if (errors.category) return;
                  if (event.keyCode === 13 || event.keyCode === 32) {
                    handleSaveGalleryImage(event);
                  }
                  handleSaveGalleryImage(event);
                }}
              >
                <button
                  type="button"
                  className="button"
                  aria-label={dictionary.Modal.Gallery.SelectButton}
                  disabled={errors.category}
                >
                  {dictionary.Modal.Gallery.SelectButton}
                </button>
              </div>
              {errors.category && (
                <p className="input-error-message">
                  {dictionary.Errors.GalleryImages}
                </p>
              )}
            </div>
          )}
        </div>

        <div
          className={`file-modal__upload-tab-content ${
            isUploadActive === "uploadActive" ? "tab--active" : ""
          }`}
        >
          <button
            type="button"
            className="file-modal__upload-tab"
            aria-label={dictionary.Modal.UploadImageTab}
            onClick={(event) => {
              event.preventDefault();
              if (event.keyCode === 13 || event.keyCode === 32) {
                setIsUploadActive("uploadActive");
              }
              setIsUploadActive("uploadActive");
            }}
          >
            {dictionary.Modal.UploadImageTab}
          </button>
          {isUploadActive === "uploadActive" && !imgSrc && (
            <div className="file-modal__container">
              <Dropzone
                handleImageChange={handleImageChange}
                dictionary={dictionary}
                imageAccept={imageAccept}
              />
              <div
                className={`file-modal__file-upload ${
                  imageSrcError.length > 0 ? "input--error" : ""
                }`}
              >
                <label htmlFor="modal-image-file" className="button">
                  <input
                    type="file"
                    name="modal-image-file"
                    aria-label={dictionary.Modal.Upload.ImportFileButton}
                    accept={imageAccept}
                    onChange={(e) => handleImageChange(e.target.files[0])}
                  />
                  <span>{dictionary.Modal.Upload.ImportFileButton}</span>
                </label>
              </div>
              {imageSrcError.length > 0 && (
                <p className="input-error-message">{imageSrcError}</p>
              )}
              <p>{dictionary.Modal.Upload.Description}</p>
            </div>
          )}
        </div>

        {isUploadActive !== "galleryActive" && imgSrc && (
          <div className="file-modal__image-crop file-modal__container">
            <div className="file-modal__image-crop-inner">
              <ImageCropper
                imgSrc={imgSrc}
                setCroppedImage={setCroppedImage}
                closeModal={closeModal}
                setImgSrc={setImgSrc}
                dictionary={dictionary}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ModalImageUpload;
