import { useEffect, useState, useRef } from "react";
import { store, useGlobalState } from "state-pool";
import * as helpers from "../shared/helpers";

export default function MemberPopup(props) {
  // const [chosenParty, setChosenParty, updateChosenParty] = useGlobalState('chosenParty');
  const [chosenParties, setChosenParties, updateChosenParties] =
    useGlobalState("chosenParties");
  const [detailedMemberList, setDetailedMemberList, updateDetailedMemberList] =
    useGlobalState("detailedMemberList");
  const [chosenMember, setChosenMember] = useGlobalState("chosenMember");
  const [memberFilter, setMemberFilter, updateMemberFilter] =
    useGlobalState("memberFilter");
  const [committeeFilter, setCommitteeFilter, updateCommitteeFilter] =
    useGlobalState("committeeFilter");
  const [constituencyFilter, setConstituencyFilter, updateConstituencyFilter] =
    useGlobalState("constituencyFilter");
  const [
    ministerialTitleFilter,
    setMinisterialTitleFilter,
    updateMinisterialTitleFilter,
  ] = useGlobalState("ministerialTitleFilter");
  const [
    rapporteurshipFilter,
    setRapporteurshipFilter,
    updateRapporteurshipFilter,
  ] = useGlobalState("rapporteurshipFilter");

  const [appdata] = useGlobalState("appdata");
  const [isDisabled, setIsDisabled] = useState(false);
  const [originalDetailedMemberList, setOriginalDetailedMemberList] = useState(
    []
  );
  const member = props.member;
  let memberHasInfo = false;
  memberHasInfo = member !== undefined && member.firstName ? true : false;
  const [facetedFilters, setFacetedFilters] = useGlobalState("facetedFilters");
  const [isMinister, setIsMinister] = useGlobalState("isMinister");
  const [isAllMinister, setIsAllMinister] = useGlobalState("isAllMinister");
  const [isFiltered] = useGlobalState("isFiltered");
  const [isFromDropdownFilter, setIsFromDropdownFilter] = useGlobalState(
    "isFromDropdownFilter"
  );
  const [
    dataVisualizationDetailedMemberList,
    setDataVisualizationDetailedMemberList,
  ] = useGlobalState("dataVisualizationDetailedMemberList");
  const isScrollToBottom = useRef(false);
  const [chosenVoteResultMember, setChosenVoteResultMember] = useGlobalState(
    "chosenVoteResultMember"
  );

  const $ = window.$;

  useEffect(() => {
    function checkFilters() {
      if (
        parseInt(memberFilter.value) !== 0 ||
        parseInt(committeeFilter.value) !== 0 ||
        parseInt(constituencyFilter.value) !== 0 ||
        parseInt(ministerialTitleFilter.value) !== 0 ||
        parseInt(rapporteurshipFilter.value) !== 0
      ) {
        if (member) {
          let filters = {
            isMember: null,
            isInCommittee: null,
            isInConstituency: null,
            isInMinisters: null,
            isInRapporteurship: null,
          };
          if (parseInt(memberFilter.value) !== 0) {
            setIsMinister(false);
            if (member[memberFilter.key] === memberFilter.value) {
              filters.isMember = true;
            } else {
              filters.isMember = false;
            }
          }
          if (parseInt(committeeFilter.value) !== 0) {
            setIsMinister(false);
            if (
              helpers.findKeyValueRecursively(
                member,
                committeeFilter.key,
                committeeFilter.value
              )
            ) {
              filters.isInCommittee = true;
            } else {
              filters.isInCommittee = false;
            }
          }
          if (parseInt(constituencyFilter.value) !== 0) {
            setIsMinister(false);
            if (member[constituencyFilter.key] === constituencyFilter.value) {
              filters.isInConstituency = true;
            } else {
              filters.isInConstituency = false;
            }
          }
          if (parseInt(ministerialTitleFilter.value) !== 0) {
            setIsMinister(true);
            // show all ministers
            if (parseInt(ministerialTitleFilter.value) === 999) {
              setIsAllMinister(true);
              if (
                appdata.ministerialTitles.ministerialPost.findIndex(
                  (minister) =>
                    minister.personId === member[ministerialTitleFilter.key]
                ) > -1
              ) {
                filters.isInMinisters = true;
              } else {
                filters.isInMinisters = false;
              }
            } else {
              if (
                member[ministerialTitleFilter.key] ===
                ministerialTitleFilter.value
              ) {
                filters.isInMinisters = true;
              } else {
                filters.isInMinisters = false;
              }
            }
          }
          if (parseInt(rapporteurshipFilter.value) !== 0) {
            setIsMinister(false);
            if (
              helpers.findKeyValueRecursively(
                member.rapporteurships,
                rapporteurshipFilter.key,
                rapporteurshipFilter.value
              )
            ) {
              filters.isInRapporteurship = true;
            } else {
              filters.isInRapporteurship = false;
            }
          }

          let filtersValue;
          if (
            appdata.constituencies !== undefined &&
            appdata.spokesmen !== undefined
          ) {
            filtersValue = [
              {
                isMember: filters.isMember,
                labelKey: "fullName",
                filterName: "memberFilter",
                id: "Medlemmer",
                updateFilter: "updateMemberFilter",
                value: memberFilter.value,
                name:
                  appdata !== null
                    ? appdata.members.member.filter(
                        (member) => member.personId === memberFilter.value
                      )
                    : [],
              },
              {
                isInCommittee: filters.isInCommittee,
                labelKey: "committeeName",
                filterName: "committeeFilter",
                id: "Udvalg",
                updateFilter: "updateCommitteeFilter",
                value: committeeFilter.value,
                name:
                  appdata !== null
                    ? appdata.committees.committee.filter(
                        (committee) =>
                          committee.committeeAbbreviation ===
                          committeeFilter.value
                      )
                    : [],
              },
              {
                isInConstituency: filters.isInConstituency,
                labelKey: "constituencyName",
                filterName: "constituencyFilter",
                id: "Valgkreds",
                updateFilter: "updateConstituencyFilter",
                value: constituencyFilter.value,
                name:
                  appdata !== null && appdata.constituencies !== undefined
                    ? appdata.constituencies.constituency.filter(
                        (constituency) =>
                          constituency.constituencyName ===
                          constituencyFilter.value
                      )
                    : [],
              },
              {
                isInMinisters: filters.isInMinisters,
                labelKey: "ministerTitleAndPerson",
                filterName: "ministerialTitleFilter",
                id: "Regeringen",
                updateFilter: "updateMinisterialTitleFilter",
                value: ministerialTitleFilter.value,
                name:
                  appdata !== null
                    ? appdata.ministerialTitles.ministerialPost.filter(
                        (ministerialPost) =>
                          ministerialPost.personId ===
                          ministerialTitleFilter.value
                      )
                    : [],
              },
              {
                isInRapporteurship: filters.isInRapporteurship,
                labelKey: "rapporteurship",
                filterName: "rapporteurshipFilter",
                id: "Ordførerskab",
                updateFilter: "updateRapporteurshipFilter",
                value: rapporteurshipFilter.value,
                name:
                  appdata !== null &&
                  appdata.spokesmen.spokesman &&
                  appdata.spokesmen.spokesman.length > 0 &&
                  appdata.spokesmen !== undefined
                    ? appdata.spokesmen.spokesman.filter(
                        (spokesman) =>
                          spokesman.rapporteurship ===
                          rapporteurshipFilter.value
                      )
                    : [],
              },
            ];
          } else {
            filtersValue = [
              {
                isMember: filters.isMember,
                labelKey: "fullName",
                filterName: "memberFilter",
                id: "Medlemmer",
                updateFilter: "updateMemberFilter",
                value: memberFilter.value,
                name:
                  appdata !== null
                    ? appdata.members.member.filter(
                        (member) => member.personId === memberFilter.value
                      )
                    : [],
              },
              {
                isInCommittee: filters.isInCommittee,
                labelKey: "committeeName",
                filterName: "committeeFilter",
                id: "Udvalg",
                updateFilter: "updateCommitteeFilter",
                value: committeeFilter.value,
                name:
                  appdata !== null
                    ? appdata.committees.committee.filter(
                        (committee) =>
                          committee.committeeAbbreviation ===
                          committeeFilter.value
                      )
                    : [],
              },
              {
                isInConstituency: filters.isInConstituency,
                labelKey: "constituencyName",
                filterName: "constituencyFilter",
                id: "Valgkreds",
                updateFilter: "updateConstituencyFilter",
                value: constituencyFilter.value,
                name:
                  appdata !== null && appdata.constituencies !== undefined
                    ? appdata.constituencies.constituency.filter(
                        (constituency) =>
                          constituency.constituencyName ===
                          constituencyFilter.value
                      )
                    : [],
              },
              {
                isInMinisters: filters.isInMinisters,
                labelKey: "ministerTitleAndPerson",
                filterName: "ministerialTitleFilter",
                id: "Regeringen",
                updateFilter: "updateMinisterialTitleFilter",
                value: ministerialTitleFilter.value,
                name: [],
              },
              {
                isInRapporteurship: filters.isInRapporteurship,
                labelKey: "rapporteurship",
                filterName: "rapporteurshipFilter",
                id: "Ordførerskab",
                updateFilter: "updateRapporteurshipFilter",
                value: rapporteurshipFilter.value,
                name: [],
              },
            ];
          }

          setFacetedFilters(filtersValue);

          const keys = Object.keys(filters);
          let allFiltersApplies = true;
          for (const key of keys) {
            if (filters[key] !== null && filters[key] === false) {
              allFiltersApplies = false;
            }
          }
          if (allFiltersApplies) {
            setIsDisabled(false);

            if (appdata !== null) {
              appdata.seats.seat.filter(
                (seat) => seat.seatNumber === member.seatNumber
              )[0].filtered = true;
            }
          } else {
            setIsDisabled(true);
            if (appdata !== null) {
              appdata.seats.seat.filter(
                (seat) => seat.seatNumber === member.seatNumber
              )[0].filtered = false;
            }
          }
        } else {
          setIsDisabled(true);
        }
      } else {
        setIsDisabled(false);
      }
    }
    if (props.useFilters) {
      checkFilters();
    }

    if (isFromDropdownFilter) {
      const hasFilterValue =
        parseInt(memberFilter.value) !== 0 ||
        parseInt(committeeFilter.value) !== 0 ||
        parseInt(constituencyFilter.value) !== 0 ||
        parseInt(ministerialTitleFilter.value) !== 0 ||
        parseInt(rapporteurshipFilter.value) !== 0;

      const membersToPush =
        appdata !== null && hasFilterValue
          ? appdata.seats.seat.filter(
              (seat) => seat.filtered !== undefined && seat.filtered === true
            )
          : [];
      setDetailedMemberList(membersToPush);
      setIsFromDropdownFilter(false);
    }

    if (chosenParties.length === 0 && !chosenMember && !isFiltered) {
      const membersToPush =
        appdata !== null
          ? appdata.seats.seat.filter(
              (seat) => seat.filtered !== undefined && seat.filtered === true
            )
          : [];
      setDetailedMemberList(membersToPush);
    }
  }, [
    memberFilter,
    committeeFilter,
    constituencyFilter,
    ministerialTitleFilter,
    rapporteurshipFilter,
    appdata,
  ]);

  useEffect(() => {
    goToChosenMember();
  }, [detailedMemberList]);

  const goToChosenMember = () => {
    const chosenMemberMobile = document.getElementById("chosen-member-mobile");
    if (
      chosenMemberMobile &&
      window.innerWidth < 1024 &&
      isScrollToBottom.current
    ) {
      document.getElementById("chosen-member-mobile").scrollIntoView({
        behavior: "smooth",
      });
    }
    isScrollToBottom.current = false;
  };

  const VoteResultMemberToggleMobile = (voteResultMember) => {
    if (!props.isVoteResult) return;
    if (
      chosenVoteResultMember &&
      voteResultMember.personId === chosenVoteResultMember.personId
    ) {
      setChosenVoteResultMember("");
      return;
    }

    setChosenVoteResultMember(voteResultMember);
  };

  function onToggle(member) {
    $(".members").fadeOut();
    setTimeout(() => {
      $(".members").fadeIn();
    }, 0);
    VoteResultMemberToggleMobile(member);

    if (props.isClickable && member.stemme !== "Fravaer") {
      if (memberHasInfo) {
        chosenMember === member.seatNumber
          ? setChosenMember(null)
          : setChosenMember(member.seatNumber);
        chosenMember === member.seatNumber
          ? setDataVisualizationDetailedMemberList([])
          : setDataVisualizationDetailedMemberList([member]);
      }
      if (!chosenMember || chosenMember !== member.seatNumber) {
        const memberToPush = appdata.seats.seat.find(
          (seat) => seat.seatNumber === member.seatNumber
        );
        setChosenMember(memberToPush.seatNumber);
        setDataVisualizationDetailedMemberList([memberToPush]);
        const memberExistsInList = detailedMemberList.find(
          (member) => member.seatNumber === memberToPush.seatNumber
        );
        if (memberExistsInList && detailedMemberList.length > 1) {
          const membersToPush = appdata.seats.seat.filter((seat) =>
            chosenParties.includes(seat.partyAbbreviation)
          );
          membersToPush.sort(function (a, b) {
            return chosenParties.indexOf(a.partyAbbreviation) <
              chosenParties.indexOf(b.partyAbbreviation)
              ? -1
              : 1;
          });

          setDetailedMemberList(membersToPush);
          if (
            chosenParties.length === 0 &&
            detailedMemberList.length > 0 &&
            (facetedFilters.filter((item) => item.name.length > 0).length > 0 ||
              isMinister)
          ) {
            const index = detailedMemberList.findIndex(
              (member) => member.seatNumber === memberToPush.seatNumber
            );

            isScrollToBottom.current = true;
            let rearrangedDetailedMemberList = [...detailedMemberList];
            const memberToArrange = rearrangedDetailedMemberList.splice(
              index,
              1
            )[0];
            rearrangedDetailedMemberList.splice(0, 0, memberToArrange);
            setDetailedMemberList(rearrangedDetailedMemberList);
          } else {
            const index = membersToPush.findIndex(
              (member) => member.seatNumber === memberToPush.seatNumber
            );

            isScrollToBottom.current = true;
            let rearrangedDetailedMemberList = [...membersToPush];
            const memberToArrange = rearrangedDetailedMemberList.splice(
              index,
              1
            )[0];
            rearrangedDetailedMemberList.splice(0, 0, memberToArrange);
            setDetailedMemberList(rearrangedDetailedMemberList);
          }
        } else {
          setIsAllMinister(false);
          setIsMinister(false);
          setDetailedMemberList([memberToPush]);
          isScrollToBottom.current = true;
        }
      } else if (chosenMember && chosenParties.length > 0) {
        //reset/restore members on list
        const membersToPush = appdata.seats.seat.filter((seat) =>
          chosenParties.includes(seat.partyAbbreviation)
        );

        membersToPush.sort(function (a, b) {
          return chosenParties.indexOf(a.partyAbbreviation) <
            chosenParties.indexOf(b.partyAbbreviation)
            ? -1
            : 1;
        });

        setDetailedMemberList(membersToPush);

        setChosenMember(null);
        setDataVisualizationDetailedMemberList([]);
        $(".member-popup").blur();
      } else {
        //reset/restore members on list
        const membersToPush = appdata.seats.seat.filter(
          (seat) => seat.filtered !== undefined && seat.filtered === true
        );
        setDetailedMemberList(membersToPush);

        setChosenMember(null);
        setDataVisualizationDetailedMemberList([]);
        $(".member-popup").blur();

        if (
          chosenParties.length > 0 ||
          (chosenMember && detailedMemberList.length === 1)
        ) {
          if (
            !facetedFilters.filter((item) => item.name.length > 0).length > 0
          ) {
            setDetailedMemberList([]);
          }
        }
      }
    } else {
      $(".member-popup").blur();
      setChosenMember(null);
      setDataVisualizationDetailedMemberList([]);
    }
  }

  if (member !== undefined) {
    return (
      <div
        tabIndex={
          (chosenParties.length > 0 &&
            chosenParties.filter(
              (chosenParty) => chosenParty !== member.partyAbbreviation
            ).length >
              chosenParties.length - 1) ||
          isDisabled ||
          member.stemme === "Fravaer" ||
          !memberHasInfo
            ? "-1"
            : "0"
        }
        key={member.seatNumber}
        id={member.seatNumber}
        aria-hidden={
          (chosenParties.length > 0 &&
            chosenParties.filter(
              (chosenParty) => chosenParty !== member.partyAbbreviation
            ).length >
              chosenParties.length - 1) ||
          isDisabled ||
          !memberHasInfo
            ? "true"
            : "false"
        }
        className={`member-popup
                            ${!memberHasInfo && "member-popup--disabled"}
                            ${
                              chosenMember === member.seatNumber &&
                              "member-popup--is-active"
                            }
                            ${
                              props.isVoteResult &&
                              member.member === "0" &&
                              "member-popup--disabled"
                            }
                            ${
                              member.stemme !== "Fravaer" ? "" : "prevent-hover"
                            }
                            ${
                              (chosenParties.length > 0 &&
                                chosenParties.filter(
                                  (chosenParty) =>
                                    chosenParty !== member.partyAbbreviation
                                ).length >
                                  chosenParties.length - 1) ||
                              isDisabled
                                ? "member-popup--disabled"
                                : ""
                            }`}
        style={{ top: props.positionY + "%", left: props.positionX + "%" }}
        onClick={() => onToggle(member)}
        onKeyPress={() => onToggle(member)}
      >
        <span
          className={`member-popup__dot ${
            member.stemme !== "Fravaer" ? "" : "prevent-hover"
          }`}
          style={{
            backgroundColor: member.dotColor,
            borderColor: member.strokeColor,
          }}
        ></span>
        {memberHasInfo && member.stemme !== "Fravaer" && (
          <div
            className={`member-popup__information ${
              props.positionX >= 50 ? "right-aligh" : ""
            }`}
          >
            <div className="member-popup__information-content">
              <div className="member-popup__img-wrapper">
                <img
                  loading="lazy"
                  alt={member.lastName}
                  // style={{backgroundColor: member.dotColor}}
                  src={member.picture}
                  className="member-popup__img"
                />
              </div>
              <div className="member-popup__info">
                <p className="member-popup__name">
                  {member.firstName} {member.lastName}
                </p>
                <p>{member.party}</p>
                <p>{member.title3}</p>
              </div>
              {chosenMember === member.seatNumber && (
                <button
                  className="member-popup__close-btn"
                  onClick={() => onToggle(member)}
                >
                  <span className="icon-ft_accordionplus"></span>
                </button>
              )}
            </div>
          </div>
        )}
      </div>
    );
  } else {
    return "";
  }
}
