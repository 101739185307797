import React from "react";
import ReactDOM from "react-dom";
import { HashRouter as Router } from "react-router-dom";

import YouthParliamentProposal from "./create-post";

const YouthParliamentProposalElement = document.getElementById("youth-parliament-create-post");

if (YouthParliamentProposalElement) {
  ReactDOM.render(
    <React.StrictMode>
      <Router>
        <YouthParliamentProposal />
      </Router>
    </React.StrictMode>,
    YouthParliamentProposalElement
  );
}
