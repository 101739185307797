/**
 * Class: utilities
 * Description: Utilities functions
 */

export const html = document.querySelectorAll("html");
export const body = document.querySelectorAll("body");
export const main = document.querySelectorAll("#main");

export let modules = [];

// short an sweet polyfill for ie11 forEach
if (window.NodeList && !NodeList.prototype.forEach) {
  NodeList.prototype.forEach = Array.prototype.forEach;
}
if (window.HTMLCollection && !HTMLCollection.prototype.forEach) {
  HTMLCollection.prototype.forEach = Array.prototype.forEach;
}

export function initComponent(moduleClass, config) {
  // init new modules and
  if (config && config.el) {
    if (typeof config.el === "string") {
      config.el = document.querySelectorAll(config.el);
    }
    if (typeof config.el == "object") {
      config.el.forEach(function (el, index) {
        // let localConfig = { ...config, ...{ el: el } };
        let localConfig = Object.assign({}, config, { el: el });
        if (localConfig.name) {
          if (index > 0) {
            console.warn(
              "Module init: more than one module with the selector exists in the dom, be aware that there will only be on in the modulelist"
            );
          }
          modules[localConfig.name] = new moduleClass(localConfig);
        } else {
          modules.push(new moduleClass(localConfig));
        }
      });
    }
  } else {
    console.warn(
      "Module init: Please check that the provided selector and module-class exists"
    );
  }
}
export function debounce(func, wait, immediate) {
  var timeout;
  return function () {
    var context = this,
      args = arguments;
    var later = function () {
      timeout = null;
      if (!immediate) {
        func.apply(context, args);
      }
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait || 200);
    if (callNow) {
      func.apply(context, args);
    }
  };
}

export function isDomElement(obj) {
  return obj instanceof Element || obj instanceof HTMLDocument;
}
export function setAriaProp(el, prop, value) {
  el.setAttribute("aria-" + prop, value);
}

export function focusWhitin() {
  var slice = [].slice;
  var removeClass = function (elem) {
    elem.classList.remove("js-focus-within");
  };
  var update = (function () {
    var running, last;
    var action = function () {
      var element = document.activeElement;
      running = false;
      if (last !== element) {
        last = element;
        slice
          .call(document.getElementsByClassName("js-focus-within"))
          .forEach(removeClass);
        while (element && element.classList) {
          element.classList.add("js-focus-within");
          element = element.parentNode;
        }
      }
    };
    return function () {
      if (!running) {
        requestAnimationFrame(action);
        running = true;
      }
    };
  })();
  document.addEventListener("focus", update, true);
  document.addEventListener("blur", update, true);
  update();
}

export function formatDate(date) {
  const tempDate = new Date(date);
  const dateString =
    ("0" + tempDate.getDate()).slice(-2) +
    "." +
    ("0" + (tempDate.getMonth() + 1)).slice(-2) +
    "." +
    tempDate.getFullYear();
  return dateString;
}

export function isElementInViewport(el) {
  var rect = el.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}

export const keys = {
  enter: 13,
  backspace: 8,
  tab: 9,
  escape: 27,
  pageup: 33,
  pagedown: 34,
  end: 35,
  home: 36,
  leftarrow: 37,
  uparrow: 38,
  rightarrow: 39,
  downarrow: 40,
};
