import CreatePostPersonalDetails from "../components/create-post-personal-details/create-post-personal-details.component";
import BasicCaptcha from "../../../Global/Components/Captcha/basic-captcha";
import ButtonPrevious from "../components/button-previous/button-previous.component";
import ButtonSubmit from "../components/button-submit/button-submit.component";

const FormStep2 = (props) => {
  const {
    register,
    unregister,
    errors,
    dictionary,
    watch,
    getValues,
    setValue,
    trigger,
    hasVideo,
    isCaptchaValidated,
    captchaMetaData,
    setIsCaptchaValidated,
    loadStepOne,
    isValid,
    loading,
    contactTypes,
    currentContactType,
    setCurrentContactType
  } = props;

  return (
    <div
      className={`forum-create-post__content ${
        loading ? "forum-create-post__content--loading" : ""
      }`}
    >
      <CreatePostPersonalDetails
        register={register}
        unregister={unregister}
        errors={errors}
        dictionary={dictionary}
        watch={watch}
        getValues={getValues}
        setValue={setValue}
        triggerValidation={trigger}
        hasVideo={hasVideo}
        contactTypes={contactTypes}
        currentContactType={currentContactType}
        setCurrentContactType={setCurrentContactType}
      />
      <BasicCaptcha
        isCaptchaValidated={isCaptchaValidated}
        captchaMetaData={captchaMetaData}
        setIsCaptchaValidated={setIsCaptchaValidated}
      />

      <div className="forum-create-post__buttons">
        <ButtonPrevious onStepChange={loadStepOne} dictionary={dictionary} />
       
        <ButtonSubmit
          isValid={isValid}
          dictionary={dictionary}
          loading={loading}
        />
      </div>
    </div>
  );
};

export default FormStep2;
