import React from "react";

const CheckboxGroup = (props) => {
  const { register, errors, dictionary, classLevelOptions, name } = props;

  return (
    <div className="forum-input-row row">
      <div className="forum-input-col col-sm-12">
        <div
          className="checkbox-buttons__container"
          role="group"
          aria-label="grades"
        >
          <p className={`checkbox__container`}>
            {dictionary.Labels.ClassLevel}
          </p>
          <div
            className={`checkbox-buttons ${
              errors.holdClass ? "checkbox--error" : ""
            }`}
          >
            {classLevelOptions.map((item, idx) => (
              <label
                htmlFor={item.label}
                key={item.id}
                className={`checkbox__container`}
              >
                <span className="check-label">{item.label}</span>
                <span className="custom-checkbox">
                  <input
                    name={name}
                    id={item.label}
                    type="checkbox"
                    aria-label={item.label}
                    tabIndex={idx === 0 ? 0 : undefined}
                    value={item.value}
                    className={errors[item.value] ? "input--error" : ""}
                    {...register(name, {
                      required: {
                        value: true,
                        message: dictionary.Errors.Required.ClassLevel,
                      },
                    })}
                  />
                  <span className="icon"></span>
                </span>
              </label>
            ))}
          </div>
        </div>
        {errors.holdClass && (
          <div className="input-error-message checkbox-error-message">{errors.holdClass.message}</div>
        )}
      </div>
    </div>
  );
};

export default CheckboxGroup;
