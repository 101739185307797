import React, { useEffect, useState, useLayoutEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  HashRouter as Router,
  Switch,
  Route,
  NavLink,
  useRouteMatch,
} from "react-router-dom";
import { useGlobalState } from "state-pool";
import SeatsData from "../../data/seats.json";
import Intro from "../../components/Intro";
import FilterDropdown from "../../components/FilterDropdown";
import DatePicker from "../../components/Date_picker";
import Seats from "../../components/Seats";
import MemberList from "../../components/MemberList";
import Reset from "../../components/Reset_button";
import FacetedFilters from "../../components/FacetedFilters";
import * as helpers from "../../shared/helpers";

import PrintlayoutChamberOverview from "../../components/PrintlayoutChamberOverview";
import Parties from "../../components/Parties";

const Preview = () => {
  const history = useHistory();
  //const location = useLocation();
  //let {path, url} = useRouteMatch();
  const [appdata] = useGlobalState("appdata");
  const title = useGlobalState("overviewTitle");
  const manchet = useGlobalState("overviewManchet");
  const [date, setDate] = useGlobalState("date");
  const [memberFilter, setMemberFilter] = useGlobalState("memberFilter");
  const [committeeFilter, setCommitteeFilter] =
    useGlobalState("committeeFilter");
  const [constituencyFilter, setConstituencyFilter] =
    useGlobalState("constituencyFilter");
  const [ministerialTitleFilter, setMinisterialTitleFilter] = useGlobalState(
    "ministerialTitleFilter"
  );
  const [rapporteurshipFilter, setRapporteurshipFilter] = useGlobalState(
    "rapporteurshipFilter"
  );
  const [previousDropdownId, setPreviousDropdownId] = useState("");
  const [isDesktopView, setIsDesktopView] = useState(window.innerWidth >= 900);
  const $ = window.$;

  const PrintDate =
    appdata.date.searchDate.substring(8, 10) +
    "-" +
    appdata.date.searchDate.substring(5, 7) +
    "-" +
    appdata.date.searchDate.substring(0, 4);

  return (
    <Router>
      <div className="container container-overview preview">
        <div className="chamber-overview-print-date">
          {appdata.printInfo.printInfo + " " + PrintDate}
        </div>
        <div className="row intro-container overview">
          <div className="col-sm-8">
            <Intro
              title={appdata.overskrift.overskrift}
              description={appdata.manchet.manchet}
            />
          </div>
        </div>
        <div className="row chamber-overview__filters-btn--container">
          <a
            role="button"
            tabIndex="0"
            className="chamber-overview__filters-btn"
            onClick={(e) => renderFilters()}
          >
            <i className="icon-ft_filter"></i>
          </a>
          <p>Filtrer din visning</p>
        </div>
        <div className="contentProps">
          <div className="mobile-view">
            <div className="row">
              <a
                role="button"
                className="chamber-overview__filters--close-btn"
                onClick={() => hideFilters()}
              >
                <span className="icon-ft_accordionplus"></span>
              </a>
            </div>
            <div className="faceted-filters-overlay">
              <FacetedFilters />
            </div>
            <div className="chamber-overview__filters row">
              <div className="col-sm-12 col-md-4">
                <FilterDropdown
                  data={appdata.members.member}
                  noSelection={appdata.members.noSelection}
                  label={appdata.members.label}
                  seats={appdata.seats.seat}
                  valueKey="personId"
                  labelKey="fullName"
                  filterKey="personId"
                  filterName="memberFilter"
                  defaultValue={memberFilter.value}
                  previousDropdownId={previousDropdownId}
                  setPreviousDropdownId={setPreviousDropdownId}
                />
              </div>
              <div className="col-sm-12 col-md-4">
                <FilterDropdown
                  data={appdata.committees.committee}
                  noSelection={appdata.committees.noSelection}
                  label={appdata.committees.label}
                  seats={appdata.seats.seat}
                  valueKey="committeeAbbreviation"
                  labelKey="committeeName"
                  filterKey="committeeAbbreviation"
                  filterName="committeeFilter"
                  defaultValue={committeeFilter.value}
                  previousDropdownId={previousDropdownId}
                  setPreviousDropdownId={setPreviousDropdownId}
                />
              </div>
              {appdata.ministerialTitles !== undefined && (
                <div className="col-sm-12 col-md-4">
                  <FilterDropdown
                    data={appdata.ministerialTitles.ministerialPost}
                    noSelection={appdata.ministerialTitles.noSelection}
                    allSeelction={appdata.ministerialTitles.allSeelction}
                    label={appdata.ministerialTitles.label}
                    seats={appdata.seats.seat}
                    valueKey="personId"
                    labelKey="ministerTitleAndPerson"
                    filterKey="personId"
                    filterName="ministerialTitleFilter"
                    defaultValue={ministerialTitleFilter.value}
                    previousDropdownId={previousDropdownId}
                    setPreviousDropdownId={setPreviousDropdownId}
                  />
                </div>
              )}
            </div>

            <div className="row chamber-overview__filters--filter__container">
              <a
                role="button"
                className="chamber-overview__filters--filter"
                onClick={() => hideFilters()}
              >
                Anvend
              </a>
            </div>
          </div>

          <div id="download-pdf" className="row render-seats-parties">
            {Object.keys(appdata.parties).length !== 0 && (
              <div className="col-xs-12 col-md-2 render-down no-space parties-container">
                <Parties parties={appdata.parties.party} />
              </div>
            )}
            <div className="col-xs-12 col-md-9 no-space seats-container">
              <FacetedFilters />
              <Seats />
            </div>
            <div className="col-md-1 render-seats-reset">
              <Reset data={appdata} />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <MemberList />
            </div>
          </div>
        </div>
      </div>
    </Router>
  );
};

export default Preview;
