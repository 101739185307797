import { useState } from "react";

export default function MemberDetailedDataVisualization(props) {
  let member = props.member;

  if (member) {
    return (
      <div>
        <div id="chosen-member-mobile" className="row member mobile">
          <div className="mobile__container mobile__container--data-visualization">
            <div className="col-sm-2 member__img-container">
              <img src={member.picture} className="member__img" />
            </div>
            <div className="col-sm-9 member__info--content">
              <div className="member__info">
                <p>
                  {member.firstName} {member.lastName}{" "}
                  <span
                    className="member__info--dot"
                    style={{
                      backgroundColor: member.dotColor,
                      borderColor: member.strokeColor,
                    }}
                  ></span>
                </p>
                <p className="member__info--detailes">{member.party}</p>
                <p className="member__info--detailes">{member.title3}</p>
              </div>
            </div>
            <div className="col-sm-1"></div>
          </div>
        </div>
      </div>
    );
  }
}
