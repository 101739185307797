import React, { useEffect, useState } from "react";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";

import { useUpdateEffect } from "../../hooks/useUpdateEffect";

import CreatePostHeader from "../create-post-header/create-post-header.component";
import FormStep1 from "../../routes/form-step1.component";
import FormStep2 from "../../routes/form-step2.component";
import FormStep3 from "../../routes/form-step3.component";
import ProtectedRoute from "../protected-route/protected-route.component";
import { usePrevious } from "../../hooks/usePrevious";

const Form = (props) => {
  const {
    dictionary,
    getValues,
    errorCode,
    setFormStep,
    setIsSubmitFailed,
    setIsSubmitSuccess,
    formStep,
    register,
    unregister,
    errors,
    setValue,
    croppedImage,
    setCroppedImage,
    videoSrc,
    setVideoSrc,
    watch,
    setPostTypeId,
    isValid,
    trigger,
    hasVideo,
    isCaptchaValidated,
    captchaMetaData,
    setIsCaptchaValidated,
    loading,
    isSubmitFailed,
    isSubmitSuccess,
    handleSubmit,
    onSubmit,
    loadStepOne,
    loadStepTwo,
    resetFormData,
    preloadedValues,
    isFirstFormStepValid,
    setIsFirstFormStepValid,
  } = props;

  const contactTypes = {
    EMAIL_CONTACT_TYPE: "email",
    INTERNATIONAL_CONTACT_TYPE: "international",
    DANISH_CONTACT_TYPE: "danish",
  };

  const [currentContactType, setCurrentContactType] = useState(
    contactTypes.EMAIL_CONTACT_TYPE
  );

  const location = useLocation();
  const prevLocation = usePrevious(location);

  const watchTitle = watch("title");
  const watchText = watch("text");
  const watchCategory = watch("category");

  const { FormStepOnePath, FormStepTwoPath, FormStepThreePath } =
    dictionary.FormSteps;

  var steps = {};
  steps[`/${FormStepOnePath}`] = 1;
  steps[`/${FormStepTwoPath}`] = 2;
  steps[`/${FormStepThreePath}`] = 3;

  useEffect(() => {
    if (location.pathname == `/${FormStepTwoPath}`) {
      handleFirstStepValidation();
    }
  }, [watchTitle, watchText, watchCategory]);

  useEffect(() => {
    setFormStep(steps[location.pathname]);
    if (prevLocation && prevLocation.pathname == `/${FormStepThreePath}`) {
      resetForm();
    }
  }, [location.pathname]);

  const resetForm = () => {
    if (isSubmitSuccess) {
      resetFormData(preloadedValues);
      setVideoSrc("");
      setCroppedImage("");
    }
    setIsSubmitFailed(false);
    setIsSubmitSuccess(false);
  };

  const handleFirstStepValidation = () => {
    const hasContent =
      watchTitle.length > 0 && watchText.length > 0 && watchCategory.length > 0;

    const isErrorsUndefined =
      typeof errors.category == "undefined" &&
      typeof errors.title == "undefined" &&
      typeof errors.text == "undefined";

    let hasErrors = errors.category && errors.title && errors.text;
    if (isErrorsUndefined) hasErrors = false;

    setIsFirstFormStepValid(
      hasContent &&
        !hasErrors &&
        dictionary.Placeholders.Category !== watchCategory
    );
  };

  return (
    <div className="forum-create-post">
      {(formStep === 1 || formStep === 2) && (
        <CreatePostHeader currentStep={formStep} dictionary={dictionary} />
      )}

      <div className="forum-create-post-container">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Switch>
            <Route path={`/${FormStepOnePath}`}>
              <FormStep1
                register={register}
                unregister={unregister}
                errors={errors}
                dictionary={dictionary}
                setValue={setValue}
                croppedImage={croppedImage}
                setCroppedImage={setCroppedImage}
                videoSrc={videoSrc}
                setVideoSrc={setVideoSrc}
                watch={watch}
                watchCategory={watchCategory}
                setPostTypeId={setPostTypeId}
                loadStepTwo={loadStepTwo}
                isFirstFormStepValid={isFirstFormStepValid}
                setIsFirstFormStepValid={setIsFirstFormStepValid}
                formStep={formStep}
                location={location}
                prevLocation={prevLocation}
                handleFirstStepValidation={handleFirstStepValidation}
              />
            </Route>

            <ProtectedRoute
              component={FormStep2}
              path={`/${FormStepTwoPath}`}
              isPathValid={isFirstFormStepValid}
              register={register}
              unregister={unregister}
              errors={errors}
              dictionary={dictionary}
              watch={watch}
              getValues={getValues}
              setValue={setValue}
              trigger={trigger}
              hasVideo={hasVideo}
              isCaptchaValidated={isCaptchaValidated}
              captchaMetaData={captchaMetaData}
              setIsCaptchaValidated={setIsCaptchaValidated}
              loadStepOne={loadStepOne}
              isValid={isValid}
              loading={loading}
              contactTypes={contactTypes}
              currentContactType={currentContactType}
              setCurrentContactType={setCurrentContactType}
            />

            <ProtectedRoute
              component={FormStep3}
              path={`/${FormStepThreePath}`}
              isPathValid={formStep === 3}
              dictionary={dictionary}
              getValues={getValues}
              errorCode={errorCode}
              setFormStep={setFormStep}
              setIsSubmitFailed={setIsSubmitFailed}
              formStep={formStep}
              isSubmitSuccess={isSubmitSuccess}
              isSubmitFailed={isSubmitFailed}
              loadStepOne={loadStepOne}
            />

            <Redirect to={`/${FormStepOnePath}`} />
          </Switch>
        </form>
      </div>
      {/* <pre>{JSON.stringify(watch(), null, 2)}</pre> */}
    </div>
  );
};

export default Form;
