﻿import * as datasetService from "./datasetService";

const createPost = (formData) => {
    const url = datasetService.getApiUrl();
    const options = {
        method: 'POST',
        body: formData
    }
    return fetch(url, options)
        .then(res => {
            return res;
        });
}

export { createPost }