import React from "react";
import ReactDOM from "react-dom";
// import './chamber-overview.scss';
import ChamberOverview from "./ChamberOverview";
import { store } from "state-pool";
import * as helpers from "./shared/helpers";

const ChamberOverviewElement = document.getElementById("chamber-overview");
if (ChamberOverviewElement) {
  // get apiurl
  helpers.setApiUrl(ChamberOverviewElement.dataset.apiurl);
  helpers.setAppType(ChamberOverviewElement.dataset.type);

  const queryDate = helpers.getHashQueryStringByName("date");
  var date = queryDate || "";

  // SET DEFAULT GLOBAL STATE

  store.setState("appdata", null);
  store.setState("date", date);
  store.setState("appdataLoading", false);
  store.setState(
    "chosenParty",
    helpers.getHashQueryStringByName("partyFilter") || ""
  );
  store.setState("detailedMemberList", []);
  store.setState("chosenMember", null);
  store.setState("chosenVoteResultMember", null);
  store.setState("memberFilter", {
    value: helpers.getHashQueryStringByName("memberFilter") || 0,
    key: "personId",
  });
  store.setState("committeeFilter", {
    value: helpers.getHashQueryStringByName("committeeFilter") || 0,
    key: "committeeAbbreviation",
  });
  store.setState("constituencyFilter", {
    value: helpers.getHashQueryStringByName("constituencyFilter") || 0,
    key: "constituencyName",
  });
  store.setState("ministerialTitleFilter", {
    value: helpers.getHashQueryStringByName("ministerialTitleFilter") || 0,
    key: "personId",
  });
  store.setState("rapporteurshipFilter", {
    value: helpers.getHashQueryStringByName("rapporteurshipFilter") || 0,
    key: "name",
  });

  store.setState("facetedFilters", []);

  store.setState("mainDocumentTitle", document.title);
  store.setState(
    "overviewdocumenttitle",
    ChamberOverviewElement.dataset.overviewdocumenttitle
  );
  store.setState("overviewTitle", ChamberOverviewElement.dataset.overviewtitle);
  store.setState(
    "overviewManchet",
    ChamberOverviewElement.dataset.overviewmanchet
  );
  store.setState(
    "datavisualizationdocumenttitle",
    ChamberOverviewElement.dataset.datavisualizationdocumenttitle
  );
  store.setState(
    "datavisualizationTitle",
    ChamberOverviewElement.dataset.datavisualizationtitle
  );
  store.setState(
    "datavisualizationManchet",
    ChamberOverviewElement.dataset.datavisualizationmanchet
  );

  store.setState("chosenParties", []);
  store.setState("isMinister", false);
  store.setState("isAllMinister", false);
  store.setState("isFiltered", false);
  store.setState("collapsedMember", "");
  store.setState("isValidDate", false);
  store.setState("isFromDropdownFilter", false);
  store.setState("isDatepickerTriggered", false);
  store.setState("dataVisualizationDetailedMemberList", []);

  ReactDOM.render(
    <React.StrictMode>
      <ChamberOverview />
    </React.StrictMode>,
    ChamberOverviewElement
  );
}
