import { useEffect, useState } from "react";
import { useGlobalState } from "state-pool";

export default function VoteResults() {
  const [appdata] = useGlobalState("appdata");
  const [inFavorColor, setInFavorColor] = useState();
  const [againstColor, setAgainstColor] = useState();
  const [sortedLegendItems, setSortedLegendItems] = useState([]);

  useEffect(() => {
    setInFavorColor(getVoteColor("inFavor"));
    setAgainstColor(getVoteColor("against"));
    setSortedLegendItems(sortLegendItems());
  }, []);

  const getVoteColor = (voteType) => {
    const colors = appdata.numericColors.item;
    const colorObj = colors.find((x) => x.colorType === voteType);
    return colorObj.color;
  };

  const sortLegendItems = () => {
    const legendSortedList = [];

    appdata.legend.item.forEach((item, index) => {
      item.index = index;
      if (item.index === 0) {
        legendSortedList.push(item);
      } else if (item.index === 2) {
        item.index = 1;
        legendSortedList.push(item);
      } else if (item.index === 1) {
        item.index = 2;
        legendSortedList.push(item);
      } else if (item.index === 3) {
        legendSortedList.push(item);
      }
    });
    legendSortedList.sort(function (a, b) {
      return a.index - b.index;
    });

    return legendSortedList;
  };
  return (
    <div className="voting__content">
      <div className="voting__results">
        <div className="voting__results--number">
          <p className="voting__for" style={{ color: inFavorColor }}>
            {appdata.result.for}
          </p>
          <p className="voting__against" style={{ color: againstColor }}>
            {appdata.result.imod}
          </p>
        </div>
        <div className="voting__types disktop-view">
          {sortedLegendItems.map((item) => (
            <div key={item.index} className="voting__types--item">
              <span
                className="voting__dot"
                style={{
                  backgroundColor: item.dotColor,
                  borderColor: item.strokeColor,
                }}
              ></span>
              <span>{item.text}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
