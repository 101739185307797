import React from "react";
import { useGlobalState } from "state-pool";
import MemberDetailed from "./MemberDetailed.jsx";

export default function MemberList(props) {
  const [detailedMemberList] = useGlobalState("detailedMemberList");
  const [chosenParty] = useGlobalState("chosenParty");
  const [chosenParties] = useGlobalState("chosenParties");
  const [date] = useGlobalState("date");
  const [appdata] = useGlobalState("appdata");
  const [facetedFilters] = useGlobalState("facetedFilters");
  const [chosenMember] = useGlobalState("chosenMember");
  const [isMinister] = useGlobalState("isMinister");
  const [isAllMinister] = useGlobalState("isAllMinister");

  /*ToDo: Seems like the date format add 1 to month, guess it expect a zero based index month, 
    have used substract 1 to get month correct*/

  const getFormattedDate = () => {
    if (date === "") {
      return new Date(
        Date.UTC(
          appdata.date.searchDate.substring(0, 4),
          appdata.date.searchDate.substring(5, 7) - 1,
          appdata.date.searchDate.substring(8, 10)
        )
      ).toLocaleDateString("da", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    } else {
      return new Date(
        Date.UTC(
          date.substring(0, 4),
          date.substring(4, 6) - 1,
          date.substring(6, 8)
        )
      ).toLocaleDateString("da", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    }
  };

  const formattedDate = getFormattedDate();

  const getChosenMemberTitle = (member) => {
    if (appdata.ministerialTitles !== undefined) {
      const ministerialPosts = appdata.ministerialTitles.ministerialPost;
      const memberMinisterialPosts = member.hasOwnProperty(
        "ministerialPosts"
      )
        ? member.ministerialPosts.ministerialPost
        : [];

      const minister = ministerialPosts.find(
        (x) => x.personId === member.personId
      );
      
      return minister
        ? `${memberMinisterialPosts.map(x => x.ministerTitle).join(", ")} ${member.firstName} ${member.lastName}`
        : `${member.firstName} ${member.lastName}`;
    } else {
      return `${member.firstName} ${member.lastName}`;
    }
  };

  const getMembersCountTitle = () => {
    if (appdata.memberCountLabels !== undefined) {
      const memberCountLabels = appdata.memberCountLabels.memberCountLabel;
      const { memberCount, ministerCount } = getMembersCount();

      if (isAllMinister) {
        return memberCountLabels
          .find((label) => label.id === "ministersOnly")
          .label.replace("${ministerCount}", ministerCount)
          .replace("${date}", formattedDate);
      }
      if (
        (memberCount === 1 && ministerCount < 1) ||
        (memberCount < 1 && ministerCount === 1)
      ) {
        return memberCountLabels
          .find((label) => label.id === "singleChosen")
          .label.replace("${date}", formattedDate);
      }
      if (memberCount > 1 && ministerCount < 1) {
        return memberCountLabels
          .find((label) => label.id === "membersOnly")
          .label.replace("${memberCount}", memberCount)
          .replace("${date}", formattedDate);
      }
      if (memberCount < 1 && ministerCount > 1) {
        return memberCountLabels
          .find((label) => label.id === "ministersOnly")
          .label.replace("${ministerCount}", ministerCount)
          .replace("${date}", formattedDate);
      }
      if (memberCount === 1 && ministerCount > 1) {
        return memberCountLabels
          .find((label) => label.id === "memberAndMinisters")
          .label.replace("${memberCount}", memberCount)
          .replace("${ministerCount}", ministerCount)
          .replace("${date}", formattedDate);
      }
      if (memberCount > 1 && ministerCount === 1) {
        return memberCountLabels
          .find((label) => label.id === "membersAndMinister")
          .label.replace("${memberCount}", memberCount)
          .replace("${ministerCount}", ministerCount)
          .replace("${date}", formattedDate);
      }
      if (memberCount > 1 && ministerCount > 1) {
        return memberCountLabels
          .find((label) => label.id === "membersAndMinisters")
          .label.replace("${memberCount}", memberCount)
          .replace("${ministerCount}", ministerCount)
          .replace("${date}", formattedDate);
      }
    } else {
      // do nothing
    }
  };

  const getMembersCount = () => {
    if (isAllMinister)
      return { memberCount: 0, ministerCount: detailedMemberList.length };

    if (appdata.ministerialTitles === undefined) {
      return { memberCount: detailedMemberList.length, ministerCount: 0 };
    }

    const counts = {
      memberCount: 0,
      ministerCount: 0,
    };

    if (appdata.seats.seat[0].member !== undefined) {
      detailedMemberList.forEach((member) =>
        member.member === "1" ? counts.memberCount++ : counts.ministerCount++
      );
    } else {
      detailedMemberList.forEach((member) =>
        appdata.ministerialTitles.ministerialPost.find(
          (ministerPost) => ministerPost.personId === member.personId
        )
          ? counts.ministerCount++
          : counts.memberCount++
      );
    }

    return counts;
  };

  const isChosenMemberMinister = () => {
    if (detailedMemberList.length !== 1) return false;

    const [chosenMember] = detailedMemberList;
    const { ministerialPosts } = chosenMember;

    if (
      (ministerialPosts && ministerialPosts.ministerialPost.length > 0) ===
      false
    ) {
      var ministerByMinisterialPost =
        appdata.ministerialTitles.ministerialPost.find(
          (x) => x.personId === chosenMember.personId
        );
      return ministerByMinisterialPost !== undefined;
    } else {
      return true;
    }
  };

  const ministerTitle = (memberList) => {
    const [member] = memberList;
    const [ministerialPost] = member.ministerialPosts.ministerialPost;
    return ministerialPost.ministerTitle;
  };

  return (
    <div>
      {chosenParties.length > 0 && (
        <div className="members-info">
          <div className="members-count__container">
            {chosenParties.map((chosenParty, index) => (
              <h2 
                key={index} 
                className="party-full-name"
              >
                {
                  appdata.parties.party.filter(
                    (seat) => seat.partyAbbreviation === chosenParty
                  )[0].partyName
                }
                <span
                  className={`separator ${
                    index === chosenParties.length - 1 ? "hide" : ""
                  }`}
                >,
                </span>
              </h2>
            ))}
          </div>
          <span className="members-count">{getMembersCountTitle()}</span>
        </div>
      )}
      {chosenParties.length === 0 &&
        detailedMemberList.length > 0 &&
        (facetedFilters.filter((item) => item.name.length > 0).length > 0 ||
          isMinister) && (
          <div className="members-info">
            {isChosenMemberMinister() && (
              <h2 
                className="party-full-name"
              >
                {getChosenMemberTitle(detailedMemberList[0])}
              </h2>
            )}
            {!isChosenMemberMinister() && (
              <h2 
                className="party-full-name"
              >
                {facetedFilters.map((item, index) =>
                  item.name[0] !== undefined || item.name[1] !== undefined
                    ? item.name[0].personId
                      ? item.name[0][Object.keys(item.name[0])[0]]
                      : item.name[0][Object.keys(item.name[0])[1]] !==
                          undefined &&
                        typeof item.name[0][Object.keys(item.name[0])[1]] ===
                          "string"
                      ? item.name[0][Object.keys(item.name[0])[1]]
                      : item.name[0][Object.keys(item.name[0])[0]]
                    : ""
                )}
              </h2>
            )}
            {isAllMinister &&
              facetedFilters.every((item) => item.name.length === 0) && (
                <h2 
                  className="party-full-name"
                >
                  {appdata.ministerialTitles.allSeelction}
                </h2>
              )}

            <span className="members-count">{getMembersCountTitle()}</span>
          </div>
        )}

      {/* click on dot with filter selected */}
      {chosenParties.length === 0 &&
        detailedMemberList.length === 1 &&
        facetedFilters.filter((item) => item.name.length === 0).length === 0 &&
        !isMinister && (
          <div className="members-info">
            {detailedMemberList.map((member, index) => (
              <h2 
                key={index} 
                className="party-full-name"
              >
                {getChosenMemberTitle(member)}
              </h2>
            ))}
            <span className="members-count">{getMembersCountTitle()}</span>
          </div>
        )}

      <div className="members contentProps">
        {detailedMemberList.map((item, index) => (
          <MemberDetailed
            key={index}
            member={item}
            showClose={
              detailedMemberList.length === 1 &&
              !chosenParty &&
              chosenMember &&
              !(
                facetedFilters.filter((item) => item.name.length > 0).length > 0
              )
            }
          />
        ))}
      </div>
    </div>
  );
}
