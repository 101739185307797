import React from "react";
import axios from "axios";
import { Captcha } from "reactjs-captcha";

class BasicCaptcha extends React.Component {
  constructor(props) {
    super(props);

    this.captchaData = props.captchaMetaData;

    this.captcha = Captcha.createCaptchaInstance({
      locale: "da-DK",
      captchaFormId: props.captchaMetaData.CaptchaFormId,
      captchaEndpointUrl: "simple-captcha-endpoint.ashx",
    });

    this.hostName = location.protocol + "//" + location.hostname;
  }

  componentDidMount() {
    $(".captcha-modal__form-title").text(this.captchaData.Text);
    $(".captcha-error-msg-span").text(this.captchaData.ErrorMessage);
    $(".captcha-button").text(this.captchaData.ButtonSubmitText);
  }

  closeModal(event) {
    event.preventDefault();
    $("#captcha-error-msg").hide();
    $("#captchaModal").hide();
    $("body").removeClass("captcha-open");
    $(".unfocus")
      .attr("tabindex", 0)
      .removeClass("unfocus")
      .removeAttr("disabled");
  }

  handleErrorMsg(errorMsg) {
    errorMsg.show();
    errorMsg.removeClass("fade-out");
  }

  onCaptchaValidateHandler(event) {
    event.preventDefault();

    const postData = {
      captchaCode: this.captcha.getCaptchaData(),
      captchaFormId: this.captcha.captchaFormId,
    };

    const hostname = location.protocol + "//" + location.hostname;
    const endpoint = "/api/sitecore/botdetectvalidation/validate";
    const fullUrl = hostname + endpoint;

    const self = this;

    axios
      .post(fullUrl, postData, {
        headers: { "Content-Type": "application/json; charset=utf-8" },
      })
      .then((response) => {
        if (response.data.success == false) {
          var errorMsg = $("#captcha-error-msg");
          this.handleErrorMsg(errorMsg);
          self.captcha.reloadCaptcha();
        } else {
          this.props.setIsCaptchaValidated(true);
          $(".unfocus")
            .attr("tabindex", 0)
            .removeClass("unfocus")
            .removeAttr("disabled");
          $("body").removeClass("captcha-open");
          $("#captchaModal").hide();
          $(".submit-btn").click();
        }
      });

    event.preventDefault();
  }

  // Handle input change event to hide the error message
  handleInputChange(event) {
    $("#captcha-error-msg").hide();
  }
  render() {
    return (
      <div id="captchaModal" className="captcha-modal">
        <div className="captcha-modal__content" role="dialog">
          <span
            className="close-captcha"
            tabIndex="0"
            role="button"
            aria-label="Luk"
            onClick={(event) => this.closeModal(event)}
            onKeyDown={(event) => {
              if (event.key !== "Enter") return;
              this.closeModal(event);
            }}
          ></span>
          <section id="main-content" className="captcha-modal__main-content">
            <div className="captcha-modal__captcha">
              <Captcha captchaFormId={this.captchaData.CaptchaFormId} />
            </div>
            <div className="captcha-modal__form">
              <label htmlFor="your_first_captcha_input">
                <span className="captcha-modal__form-title"></span>
              </label>
              <input
                id="your_first_captcha_input"
                type="text"
                className="captcha-modal__input"
                onChange={this.handleInputChange}
              />
              <div
                id="captcha-error-msg"
                className="alert alert-error captcha-error-msg"
                role="alert"
              >
                <span className="captcha-error-msg-span"></span>
              </div>
              <button
                id="submit_button"
                type="button"
                className="button captcha-button"
                onClick={(event) => this.onCaptchaValidateHandler(event)}
              />
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default BasicCaptcha;
