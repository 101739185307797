import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router";
import { useGlobalState } from "state-pool";
import { useUpdateEffect } from "../shared/customHooks";
import * as helpers from "../shared/helpers";

export default function DatePicker(props) {
  const [value, setValue] = useState(
    props.defaultDate.substring(8, 10) +
      "-" +
      props.defaultDate.substring(5, 7) +
      "-" +
      props.defaultDate.substring(0, 4)
  );
  const [dateInputField, setDateInputField] = useState(
    props.defaultDate.substring(8, 10) +
      "-" +
      props.defaultDate.substring(5, 7) +
      "-" +
      props.defaultDate.substring(0, 4)
  );
  const [previousDateInputField, setPreviousDateInputField] = useState("");
  const [hasChangedDate, setHasChangedDate] = useState(false);
  const reversedMaxDate =
    props.maxDate.substring(8, 10) +
    "-" +
    props.maxDate.substring(5, 7) +
    "-" +
    props.maxDate.substring(0, 4);
  const reversedMinDate =
    props.minDate.substring(8, 10) +
    "-" +
    props.minDate.substring(5, 7) +
    "-" +
    props.minDate.substring(0, 4);
  const isMounted = useRef(false);
  const [chosenParties, setChosenParties, updateChosenParties] =
    useGlobalState("chosenParties");
  const [detailedMemberList, setDetailedMemberList, updateDetailedMemberList] =
    useGlobalState("detailedMemberList");
  const [
    dataVisualizationDetailedMemberList,
    setDataVisualizationDetailedMemberList,
  ] = useGlobalState("dataVisualizationDetailedMemberList");
  const [chosenMember, setChosenMember] = useGlobalState("chosenMember");
  const [memberFilter, setMemberFilter, updateMemberFilter] =
    useGlobalState("memberFilter");
  const [committeeFilter, setCommitteeFilter, updateCommitteeFilter] =
    useGlobalState("committeeFilter");
  const [constituencyFilter, setConstituencyFilter, updateConstituencyFilter] =
    useGlobalState("constituencyFilter");
  const [
    ministerialTitleFilter,
    setMinisterialTitleFilter,
    updateMinisterialTitleFilter,
  ] = useGlobalState("ministerialTitleFilter");
  const [
    rapporteurshipFilter,
    setRapporteurshipFilter,
    updateRapporteurshipFilter,
  ] = useGlobalState("rapporteurshipFilter");
  const [facetedFilters, setFacetedFilters] = useGlobalState("facetedFilters");
  const history = useHistory();
  const [date] = useGlobalState("date");
  const [isDatepickerTriggered, setIsDatepickerTriggered] = useGlobalState(
    "isDatepickerTriggered"
  );

  useEffect(() => {
    const $ = window.$;
    isMounted.current = true;
    let currentDatepickerKeycode = "";
    let previousDatepickerKeycode = "";

    $("input.datepicker")
      .keydown(function (e) {
        if (
          e.which === 13 &&
          !isArrowKeycode(previousDatepickerKeycode) &&
          (isBeforeMinDate(e.target.value) || isAfterMaxDate(e.target.value))
        ) {
          e.stopImmediatePropagation();
        }
        previousDatepickerKeycode = currentDatepickerKeycode;
        currentDatepickerKeycode = e.which;
      })
      .datepicker({
        format: "dd-mm-yyyy",
        weekStart: 1,
        language: "da",
        autoclose: true,
        startDate: reversedMinDate,
        forceParse: false,
        beforeShowDay: function (date) {
          const formattedDate = formatDate(date);
          const formattedDateYearFirst = formatDateToYearFirst(formattedDate);
          if (
            props.nonDates.findIndex((x) => x.nonDate === formattedDate) !==
              -1 ||
            formattedDateYearFirst > props.maxDate ||
            (props.isYearChartSelected &&
              formattedDateYearFirst < props.yearChartMinDate)
          )
            return "disabled";
        },
      })
      .blur(function (e) {
        if (
          !$(".datepicker-days").is(":visible") &&
          !$(".datepicker-months").is(":visible") &&
          !$(".datepicker-years").is(":visible")
        ) {
          $(".date-picker-wrapper").addClass("close");
        }
        setIsDatepickerTriggered(false);
      })
      .on("hide", function (e) {
        if (isMounted.current) {
          setDateInputField(e.target.value);
        }
      })
      .on("show", function (e) {
        if (isMounted.current) {
          if ($(".mobile-view-date-picker").is(":visible")) {
            $(".datepicker-days, .datepicker-months, .datepicker-years")
              .find("table")
              .width(
                $(".mobile-view-date-picker .date-picker-wrapper").width()
              );
          } else {
            $(".datepicker-days, .datepicker-months, .datepicker-years")
              .find("table")
              .width($(".date-picker-wrapper").width());
          }
          $(".date-picker-wrapper").removeClass("close");
        }
      })
      .change(function (e) {
        if (isMounted.current) {
          if (isArrowKeycode(currentDatepickerKeycode)) {
            currentDatepickerKeycode = "";
            return;
          }
          setDateInputField(e.target.value);
          setHasChangedDate((prevHasChangedDate) => !prevHasChangedDate);
        }
      });
    return () => {
      isMounted.current = false;
      $("input.datepicker").off();
    };
  }, []);

  useUpdateEffect(() => {
    if (
      isValidDateInput(dateInputField) &&
      !isNonDate(dateInputField) &&
      !isBeforeMinDate(dateInputField) &&
      !isAfterMaxDate(dateInputField)
    ) {
      props.setDate(
        dateInputField.split("-").reverse().join("-").replace(/-/g, "")
      );
      setValue(dateInputField);
      setIsDatepickerTriggered(true);
    }

    if (!$(".datepicker-days").is(":visible")) {
      $(".date-picker-wrapper").addClass("close");
      $(".datepicker").blur();
      setIsDatepickerTriggered(false);
    }
    $("#content").removeClass("noScroll");
    reset();
  }, [hasChangedDate]);

  function reset() {
    setChosenParties([]);
    setChosenMember(null);
    setDataVisualizationDetailedMemberList([]);
    facetedFilters.forEach((element) => {
      element.name = [];
    });
    setFacetedFilters([]);

    setDetailedMemberList([]);

    // Update all chosen selects
    updateMemberFilter((filter) => {
      filter.value = 0;
    });

    $("#Medlemmer").val(0).trigger("chosen:updated");

    updateCommitteeFilter((filter) => {
      filter.value = 0;
    });

    $("#Udvalg").val(0).trigger("chosen:updated");
    updateConstituencyFilter((filter) => {
      filter.value = 0;
    });
    $("#Valgkreds").val(0).trigger("chosen:updated");

    updateMinisterialTitleFilter((filter) => {
      filter.value = 0;
    });
    $("#Regeringen").val(0).trigger("chosen:updated");

    updateRapporteurshipFilter((filter) => {
      filter.value = 0;
    });
    $("#Ordførerskab").val(0).trigger("chosen:updated");

    history.push({
      search: helpers.updateUrlParameter("", "date", date),
    });
  }

  const isArrowKeycode = (keycode) => {
    return keycode === 37 || keycode === 38 || keycode === 39 || keycode === 40;
  };

  const handleDateInputInfoMessage = (input) => {
    if (!input) return;
    if (!isValidDateInput(input)) {
      return props.dateFeedbackLabels.label.filter(
        (titleLabel) => titleLabel.id === "wrongFormat"
      )[0].label;
    }
    if (isBeforeMinDate(input)) {
      return props.dateFeedbackLabels.label.filter(
        (titleLabel) => titleLabel.id === "earlyDate"
      )[0].label;
    }
    if (isAfterMaxDate(input)) {
      return props.dateFeedbackLabels.label.filter(
        (titleLabel) => titleLabel.id === "futureDate"
      )[0].label;
    }
    if (isNonDate(input)) {
      return props.dateFeedbackLabels.label.filter(
        (titleLabel) => titleLabel.id === "noMeeting"
      )[0].label;
    }
  };

  const isBeforeMinDate = (date) => {
    return formatDateToYearFirst(date) < props.minDate;
  };

  const isAfterMaxDate = (date) => {
    return formatDateToYearFirst(date) > props.maxDate;
  };

  const isNonDate = (date) => {
    if (!date) return;
    return props.nonDates.some((x) => x.nonDate === date);
  };

  const isValidDateInput = (input) => {
    if (!/^\d\d\-\d\d\-\d\d\d\d$/.test(input)) {
      return false;
    } else {
      return true;
    }
  };

  const formatDate = (dateToFormat) => {
    const day = `${dateToFormat.getDate()}`.padStart(2, "0");
    const month = `${dateToFormat.getMonth() + 1}`.padStart(2, "0");
    const year = dateToFormat.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const formatDateToYearFirst = (dateToReverse) => {
    if (!dateToReverse) return;
    return (
      dateToReverse.substring(6, 10) +
      "-" +
      dateToReverse.substring(3, 5) +
      "-" +
      dateToReverse.substring(0, 2)
    );
  };

  const handleInputChange = (event) => {
    setDateInputField(event.target.value);
  };

  return (
    <div>
      <label className="page-reference__label" htmlFor="session">
        {props.label}
      </label>
      <div className="input-group date date-picker-wrapper close input-group search-field">
        <input
          onChange={handleInputChange}
          id="session"
          defaultValue={value}
          type="text"
          className="datepicker form-control"
          placeholder="dd-mm-åååå"
        />
        <span className="input-group-addon">
          <span className="glyphicon datepicker-info-message">
            {handleDateInputInfoMessage(dateInputField)}
          </span>
        </span>
      </div>
    </div>
  );
}
