import React, { useState, Fragment, useEffect } from "react";

import { useUpdateEffect } from "../../hooks/useUpdateEffect";
import * as datasetService from "../../services/datasetService";

const CreatePostContent = (props) => {
  const {
    register,
    unregister,
    errors,
    dictionary,
    setFormValue,
    watch,
    setPostTypeId,
  } = props;

  const categories = datasetService.getCategories();

  return (
    <div className="forum-create-post__debate">
      <div className="forum-create-post__input-group">
        <div className="forum-input-row row">
          <div className="forum-input-col col-md-6">
            <label
              htmlFor="CategorySelectOptions"
              className="visually-hidden"
              tabIndex={-1}
            >
              Tema
            </label>
            <select
              id="category"
              className={`custom-select ${
                errors.category ? "input--error" : ""
              }`}
              data-idname="CategorySelectOptions"
              {...register("category", {
                required: {
                  value: true,
                  message: dictionary.Errors.Required.Category,
                },
              })}
              aria-label="category"
            >
              <option value="" defaultValue hidden>
                {dictionary.Placeholders.Category}
              </option>
              {categories.map((category) => (
                <option key={category.ID} value={category.ID}>
                  {category.Name}
                </option>
              ))}
              ;
            </select>
            {errors.category && (
              <div className="input-error-message">
                {errors.category.message}
              </div>
            )}
          </div>
        </div>

        <div className="forum-input-row row">
          <div className="forum-input-col col-md-12">
            <input
              className={errors.title ? "input--error" : ""}
              {...register("title", {
                required: {
                  value: true,
                  message: dictionary.Errors.Required.DebateTitle,
                },
              })}
              type="text"
              aria-label={dictionary.Placeholders.DebateTitle}
              placeholder={dictionary.Placeholders.DebateTitle}
              tabIndex="0"
              onKeyDown={(event) => {
                if (event.key === "enter") return;
              }}
            />
            {errors.title && (
              <div className="input-error-message">{errors.title.message}</div>
            )}
          </div>
        </div>

        <div className="forum-input-row row">
          <div className="forum-input-col col-md-12">
            <textarea
              className={errors.text ? "input--error" : ""}
              {...register("text", {
                required: {
                  value: true,
                  message: dictionary.Errors.Required.DebateContentMessage,
                },
              })}
              cols="30"
              rows="10"
              aria-label={dictionary.Placeholders.DebateContentMessage}
              placeholder={dictionary.Placeholders.DebateContentMessage}
              tabIndex="0"
            />
            {errors.text && (
              <div className="input-error-message">{errors.text.message}</div>
            )}
          </div>
        </div>

        <div className="forum-input-row row">
          <div className="forum-input-col col-md-12">
            <textarea
              className={errors.comment ? "input--error" : ""}
              {...register("comment", {
                required: {
                  value: true,
                  message: dictionary.Errors.Required.DebateContentComment,
                },
              })}
              cols="30"
              rows="10"
              aria-label={dictionary.Placeholders.DebateContentComment}
              placeholder={dictionary.Placeholders.DebateContentComment}
              tabIndex="0"
            />
            {errors.comment && (
              <div className="input-error-message">
                {errors.comment.message}
              </div>
            )}
          </div>
        </div>

        <div className="forum-input-row row">
          <div className="forum-input-col col-md-12">
            <p>{dictionary.Labels.RequiredFieldText}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreatePostContent;
