import React, { forwardRef, useState, useEffect } from "react";
import Select from "react-select";
import axios from "axios";
import "../../../../../../../Styles/SubsiteWide/variables.less";

const SelectDropdown = forwardRef(
  ({ errors, triggerChange, name, dictionary }, ref) => {
    const clearLocalStorageOnUnload = () => {
      localStorage.removeItem("selectedOption");
    };

    // Add event listener to clear localStorage when page unloads
    window.addEventListener("unload", clearLocalStorageOnUnload);

    const [schools, setSchools] = useState([]);
    const storedValue = localStorage.getItem("selectedOption");
    const url = "/api/sitecore/stil/GetSchools";
    const [triggerError, setTriggerError] = useState(false);
    const [value, setValue] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
      if (storedValue) {
        setTimeout(() => {
          // Introduce a timeout to delay the initialization
          setValue(JSON.parse(storedValue));
          setLoading(false);
        }, 100);
      } else {
        setLoading(false);
      }
    }, []);

    const transformedOptions = schools.map((item) => ({
      value: item, // Entire object as the value
      label: item.Name + ", " + item.PostalCode + " " + item.City, // Display InstNavn as the label
    }));

    const handleChange = (selectedOption) => {
      if (triggerChange) {
        triggerChange(selectedOption);
        setTriggerError(false);
        setValue(selectedOption);

        // Store selected value in local storage
        localStorage.setItem("selectedOption", JSON.stringify(selectedOption));
      }
    };

    const handleMenuClose = () => {
      triggerChange && value == null
        ? setTriggerError(true)
        : setTriggerError(false);

      clearDropdown();
    };

    const fetchData = () => {
      axios
        .get(url)
        .then((response) => {
          if (response.status === 200) {
            const jsonData = response.data;
            setSchools(jsonData);
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    };

    const handleInputChange = (typedOption) => {
      if (typedOption.length > 2) {
        // insert three characters to start do the fetch
        fetchData();
      } else {
        clearDropdown();
      }
    };

    const clearDropdown = () => {
      setSchools([]);
    };

    const greyColor = "var(--grey-color)";
    const themeColor = "var(--primary-color)";
    const whiteColor = "var(--white-color)";
    const darkColor = "var(--dark-color)";
    const errorColor = "var(--error-color)";

    const colourStyles = {
      control: (styles, { isFocused, isDisabled }) => {
        return {
          display: "flex",
          backgroundColor: greyColor,
          padding: "7px 15px",
          borderRadius: "0",
          borderColor: greyColor,
          color: darkColor,
          cursor: "pointer",
          outline:
            triggerError && value == null
              ? isFocused
                ? `2px dashed ${errorColor}`
                : `2px dashed ${errorColor}`
              : isFocused
              ? `2px dashed ${darkColor}`
              : "none",
          outlineOffset: "2px",
          opacity: isDisabled ? "0.5" : "none",
          cursor: isDisabled ? "not-allowed" : "pointer",
        };
      },
      option: (styles, { isSelected, isFocused }) => {
        return {
          backgroundColor: whiteColor,
          padding: "15px 15px 18px 15px",
          cursor: "pointer",
          position: "relative",
          borderTop: `2px solid ${greyColor}`,
          "&:first-of-type": {
            borderTop: "none",
            marginTop: "-4px",
          },
          "&:last-of-type": {
            marginBottom: "-5px",
          },
          color: isSelected ? themeColor : darkColor,
          ":before": {
            content: isSelected ? '""' : "none",
            borderLeft: `6px solid ${themeColor}`,
            position: "absolute",
            height: "100%",
            top: "0",
            left: "0",
          },
          ":hover": {
            backgroundColor: isSelected ? whiteColor : themeColor,
            color: isSelected ? themeColor : whiteColor,
          },
          backgroundColor: isFocused ? themeColor : whiteColor,
          color: isFocused
            ? isSelected
              ? whiteColor
              : whiteColor
            : isSelected
            ? themeColor
            : darkColor,
        };
      },
      input: (styles) => ({
        ...styles,
        color: darkColor,
        ":disabled": {
          opacity: ".5",
          cursor: "not-allowed",
        },
      }),
      placeholder: (styles) => ({ ...styles, color: darkColor }),
      singleValue: (styles) => ({ ...styles, color: darkColor }),
    };

    return (
      <>
        {" "}
        {loading ? (
          <div>Loading...</div>
        ) : (
          <>
            <Select
              name={name}
              styles={colourStyles}
              isClearable
              className="select-dropdown-container"
              classNamePrefix="select-dropdown"
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,
                borderColor: "none",
                boxShadow: "0px 5px 12px 2px rgba(0, 0, 0, 0.3)",
              })}
              defaultValue={value || null}
              onMenuClose={handleMenuClose}
              onChange={handleChange}
              onInputChange={handleInputChange}
              options={transformedOptions}
              isSearchable // Enables search functionality
              aria-label={dictionary.Placeholders.School}
              placeholder={dictionary.Placeholders.School}
              noOptionsMessage={() => dictionary.Errors.Required.SchoolNoOptions}
              value={transformedOptions.find(
                (option) => option.value === errors[name]
              )}
              ref={ref}
            />

            {triggerError && value == null && !errors[name] && (
              <div className="input-error-message">
                {dictionary.Errors.Required.School}
              </div>
            )}
            {errors[name] && (
              <div className="input-error-message">{errors[name].message}</div>
            )}
          </>
        )}
      </>
    );
  }
);

export default SelectDropdown;
