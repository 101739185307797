import React from "react";

const ConfirmPage = ({ dictionary, getValues }) => {
  const { Title, Text } = dictionary.ConfirmPage;
  const contactInfo = () => {
    const email = getValues("email");
    const phone = getValues("mobil");
    if (typeof email !== "undefined" && email.length > 0) return email;
    return `tlf ${phone}`;
  };

  return (
    <div className="confirm-page" role="alert">
      <h2>{Title}</h2>
      <p>{Text.replace("{contactInfo}", contactInfo)}</p>
    </div>
  );
};

export default ConfirmPage;
