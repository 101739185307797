import ConfirmPage from "../pages/confimPage";
import ErrorPage from "../pages/errorPage";

const FormStep3 = (props) => {
  const {
    formStep,
    isSubmitSuccess,
    isSubmitFailed,
    dictionary,
    getValues,
    errorCode,
    setFormStep,
    setIsSubmitFailed,
    resetForm,
    loadStepOne
  } = props;

  return (
    <div className="forum-create-post-status">
      {formStep === 3 && isSubmitSuccess && (
        <ConfirmPage
          dictionary={dictionary}
          getValues={getValues}
          resetForm={resetForm}
        />
      )}
      {formStep === 3 && isSubmitFailed && (
        <ErrorPage
          errorCode={errorCode}
          dictionary={dictionary}
          setFormStep={setFormStep}
          setIsSubmitFailed={setIsSubmitFailed}
          loadStepOne={loadStepOne}
        />
      )}
    </div>
  );
};

export default FormStep3;
