import { setAriaProp } from "../../utilities.js";
// Quick aliases and polyfills if needed
var query = document.querySelector.bind(document);
var queryAll = document.querySelectorAll.bind(document);

var KEYCODES = {
  SPACE: 32,
  UP: 38,
  DOWN: 40,
  ENTER: 13,
  ESCAPE: 27,
  TAB: 9,
};

if (!NodeList.prototype.forEach) {
  NodeList.prototype.forEach = Array.prototype.forEach;
}
if (!HTMLCollection.prototype.forEach) {
  HTMLCollection.prototype.forEach = Array.prototype.forEach;
}
if (!Element.prototype.matches) {
  // See https://developer.mozilla.org/en-US/docs/Web/API/Element.matches
  Element.prototype.matches =
    Element.prototype.msMatchesSelector ||
    Element.prototype.mozMatchesSelector ||
    Element.prototype.webkitMatchesSelector ||
    Element.prototype.oMatchesSelector;
}

// IE 9-11 CustomEvent polyfill
// From https://developer.mozilla.org/en/docs/Web/API/CustomEvent
var CustomEvent = function (eventName, params) {
  params = params || {
    bubbles: false,
    cancelable: false,
    detail: undefined,
  };
  var event = document.createEvent("CustomEvent");
  event.initCustomEvent(
    eventName,
    params.bubbles,
    params.cancelable,
    params.detail
  );
  return event;
};
CustomEvent.prototype = window.Event.prototype;
window.CustomEvent = CustomEvent;

// IE10 dataset polyfill
// From https://gist.githubusercontent.com/brettz9/4093766/raw/ba31a05e7ce21af67c6cafee9b3f439c86e95b01/html5-dataset.js
if (
  !document.documentElement.dataset &&
  // FF is empty while IE gives empty object
  (!Object.getOwnPropertyDescriptor(Element.prototype, "dataset") ||
    !Object.getOwnPropertyDescriptor(Element.prototype, "dataset").get)
) {
  var propDescriptor = {
    enumerable: true,
    get: function () {
      "use strict";
      var i,
        that = this,
        HTML5_DOMStringMap,
        attrVal,
        attrName,
        propName,
        attribute,
        attributes = this.attributes,
        attsLength = attributes.length,
        toUpperCase = function (n0) {
          return n0.charAt(1).toUpperCase();
        },
        getter = function () {
          return this;
        },
        setter = function (attrName, value) {
          return typeof value !== "undefined"
            ? this.setAttribute(attrName, value)
            : this.removeAttribute(attrName);
        };
      try {
        // Simulate DOMStringMap w/accessor support
        // Test setting accessor on normal object
        ({}).__defineGetter__("test", function () {});
        HTML5_DOMStringMap = {};
      } catch (e1) {
        // Use a DOM object for IE8
        HTML5_DOMStringMap = document.createElement("div");
      }
      for (i = 0; i < attsLength; i++) {
        attribute = attributes[i];
        // Fix: This test really should allow any XML Name without
        //         colons (and non-uppercase for XHTML)
        if (
          attribute &&
          attribute.name &&
          /^data-\w[\w\-]*$/.test(attribute.name)
        ) {
          attrVal = attribute.value;
          attrName = attribute.name;
          // Change to CamelCase
          propName = attrName.substr(5).replace(/-./g, toUpperCase);
          try {
            Object.defineProperty(HTML5_DOMStringMap, propName, {
              enumerable: this.enumerable,
              get: getter.bind(attrVal || ""),
              set: setter.bind(that, attrName),
            });
          } catch (e2) {
            // if accessors are not working
            HTML5_DOMStringMap[propName] = attrVal;
          }
        }
      }
      return HTML5_DOMStringMap;
    },
  };
  try {
    // FF enumerates over element's dataset, but not
    //   Element.prototype.dataset; IE9 iterates over both
    Object.defineProperty(Element.prototype, "dataset", propDescriptor);
  } catch (e) {
    propDescriptor.enumerable = false; // IE8 does not allow setting to true
    Object.defineProperty(Element.prototype, "dataset", propDescriptor);
  }
}

// Return true if any ancestor matches selector
// Borrowed from ancestorMatches() from agave.js (MIT)
var isAncestorOf = function (element, selector, includeSelf) {
  var parent = element.parentNode;
  if (includeSelf && element.matches(selector)) {
    return true;
  }
  // While parents are 'element' type nodes
  // See https://developer.mozilla.org/en-US/docs/DOM/Node.nodeType
  while (parent && parent.nodeType && parent.nodeType === 1) {
    if (parent.matches(selector)) {
      return true;
    }
    parent = parent.parentNode;
  }
  return false;
};

// Used to match select boxes to their style select partners
var makeUUID = function () {
  return "ss-xxxx-xxxx-xxxx-xxxx-xxxx".replace(/x/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

// The 'styleSelect' main function
// selector:String - CSS selector for the select box to style
function init(selector) {
  // Use native selects (which pop up large native UIs to go through the options ) on iOS/Android
  // if (navigator.userAgent.match(/iPad|iPhone|Android/i)) {
  // 	return;
  // }

  var realSelect = typeof selector == "object" ? selector : query(selector);
  if (!realSelect) {
    return;
  }

  var icon =
    "<svg version='1.1' viewBox='0 0 13.25 23.66' xmlns='http://www.w3.org/2000/svg'><polygon transform='translate(-27.77,-20.17)' points='27.77 21.58 29.19 20.17 41.02 32 29.19 43.83 27.77 42.42 38.19 32'></polygon></svg>";
  var isFilterSelect = false;
  var isFilterSelectClass = "";
  if (realSelect.classList.contains("custom-select-filter")) {
    icon =
      '<svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M21 1H1L9 10.46V17L13 19V10.46L21 1Z" stroke="#BB4752" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>';
    isFilterSelect = true;
    isFilterSelectClass = "custom-select-filter";
  }

  var iconLogo = "";
  if (realSelect.classList.contains("custom-list-filter")) {
    iconLogo =
      '<img id="" src="../../Presentation/-/media/Logo2018/folketinget-segl" alt="logo der er flot"></img>';
    isFilterSelect = true;
    isFilterSelectClass = "custom-list-filter";
  }

  var realOptions = realSelect.children,
    selectedIndex = realSelect.selectedIndex,
    uuid = makeUUID(),
    styleSelectHTML =
      '<div class="style-select ' +
      isFilterSelectClass +
      '" data-ss-uuid="' +
      uuid +
      '">';

  // Adds '#anchor-' for solving mobile issue
  realOptions.forEach((realOption) => {
    // add '#anchor' to option value if it doesn't have
    // console.log(realOption.value)
    realOption.setAttribute("value", realOption.value);
    // if (realOption.value.substring(0, 7) != "#anchor") {
    // 	realOption.value = realOption.value.substring(1); //Remove '#'
    // 	realOption.value =
    // 		"#" +
    // 		(realOption.value.match(/^\d/) ? "anchor-" : "") +
    // 		realOption.value; //Adds '#anchor- if value starts with number'
    // }
  });

  // The index of the item that's being highlighted by the mouse or keyboard
  var highlightedOptionIndex;
  var highlightedOptionIndexMax = realOptions.length - 1;
  var arialabelValue =
    document.querySelector(
      `label[for='${realSelect.getAttribute("data-idname")}']`
    ).textContent || "";

  var idValue = realSelect.getAttribute("data-idname");
  // get the background color for the select
  var selectBgColor = realSelect.getAttribute("data-bg-color");
  var selectBgColorHtml = "";
  if (selectBgColor !== null) {
    selectBgColorHtml = 'style="background-color: ' + selectBgColor;
  }

  realSelect.setAttribute("data-ss-uuid", uuid);
  realSelect.setAttribute("aria-label", arialabelValue);
  // Even though the element is display: none, a11y users should still see it.
  // According to http://www.w3.org/TR/wai-aria/states_and_properties#aria-hidden
  // some browsers may have bugs with this but future implementation may improve
  realSelect.setAttribute("aria-hidden", "false");

  realSelect.previousElementSibling.setAttribute("aria-hidden", "true");
  realSelect.previousElementSibling.setAttribute("tabindex", "-1");

  // Build styled clones of all the real options
  var selectedOptionHTML;

  var optionsHTML =
    '<ul class="ss-dropdown" role="listbox" aria-controls="list" aria-label="' +
    arialabelValue +
    '" id="' +
    idValue +
    '" >';
  var label =
    document.querySelector(
      `label[for='${realSelect.getAttribute("data-idname")}']`
    ).textContent || "";

  // define variables for icon source
  var optionIconSrc = "";
  var optionIconHtml = "";

  realOptions.forEach(function (realOption, index) {
    var text = realOption.textContent,
      value = realOption.getAttribute("value") || "",
      cssClass = "ss-option";

    optionIconSrc = realOption.getAttribute("iconsrc");
    if (optionIconSrc !== null) {
      optionIconHtml =
        "<span class='ss-icon ss-icon__before'>" +
        '<img src="' +
        optionIconSrc +
        '" alt="' +
        text +
        '"></img>' +
        "</span>";
    } else {
      optionIconHtml = "";
    }

    if (index === selectedIndex) {
      // Mark first item as selected-option - this is where we store state for the styled select box
      // aria-hidden=true so screen readers ignore the styles selext box in favor of the real one (which is visible by default)
      selectedOptionHTML =
        '<div class="ss-selected-option" role="combobox" aria-label="' +
        label +
        '" aria-haspopup="listbox" aria-autocomplete="list" aria-activedescendant="option-1" aria-expanded="false" ' +
        selectBgColorHtml +
        '" tabindex="0" data-value="' +
        value +
        '">' +
        text +
        "<span class='ss-icon'>" +
        icon +
        "</span></div>";
    }

    if (realOption.disabled) {
      cssClass += " disabled";
    }

    // Continue building optionsHTML
    optionsHTML +=
      '<li class="' +
      cssClass +
      '" data-value="' +
      value +
      '" role="option" id="option-' +
      index +
      "_" +
      makeUUID() +
      "_" +
      arialabelValue +
      "_" +
      value +
      '">' +
      optionIconHtml +
      // "<span class='ss-value'>" +
      //   text +
      // "</span>" +

      text +
      "</li>";
  });
  optionsHTML += "</ul>";
  styleSelectHTML += selectedOptionHTML += optionsHTML += "</div>";
  // And add out styled select just after the real select
  realSelect.insertAdjacentHTML("afterend", styleSelectHTML);

  var styledSelect = query('.style-select[data-ss-uuid="' + uuid + '"]');
  var styleSelectOptions = styledSelect.querySelectorAll(".ss-option");
  var selectedOption = styledSelect.querySelector(".ss-selected-option");

  var blurEvent = new CustomEvent("blur", { bubbles: true });

  selectedOption.addEventListener("blur", (event) => {
    setTimeout(function () {
      realSelect.dispatchEvent(blurEvent);
    }, 150);
  });

  selectedOption.addEventListener("blur", (event) => {
    setTimeout(function () {
      close();
    }, 150);
  });

  var close = function () {
    styledSelect.classList.remove("open");
    setAriaProp(selectedOption, "expanded", false);
  };

  var changeRealSelectBox = function (
    newValue,
    newLabel,
    close = false,
    event = null
  ) {
    // Close styledSelect
    if (close) {
      styledSelect.classList.remove("open");
      setAriaProp(selectedOption, "expanded", false);
    }

    // Update styled value
    selectedOption.innerHTML =
      newLabel + "<span class='ss-icon'>" + icon + "</span>";
    selectedOption.dataset.value = newValue;

    // Update the 'tick' that shows the option with the current value
    styleSelectOptions.forEach(function (styleSelectOption) {
      if (styleSelectOption.dataset.value === newValue) {
        setAriaProp(
          selectedOption,
          "activedescendant",
          styleSelectOption.getAttribute("id")
        );
        setAriaProp(styleSelectOption, "selected", true);
        styleSelectOption.classList.add("ticked");
      } else {
        setAriaProp(styleSelectOption, "selected", false);
        styleSelectOption.classList.remove("ticked");
      }
    });

    // Update real select box
    realSelect.value = newValue;

    // Send 'change' event to real select - to trigger any change event listeners
    var changeEvent = new CustomEvent("change", { bubbles: true });
    realSelect.dispatchEvent(changeEvent);
  };

  // Change real select box when a styled option is clicked
  styleSelectOptions.forEach(function (option, index) {
    var styleSelectOption = styleSelectOptions.item(index);

    if (styleSelectOption.className.match(/\bdisabled\b/)) {
      return;
    }
    styleSelectOption.addEventListener("click", function (ev) {
      var target = ev.target,
        styledSelectBox = target.parentNode.parentNode,
        uuid = styledSelectBox.getAttribute("data-ss-uuid"),
        newValue = target.getAttribute("data-value"),
        newLabel = target.textContent;

      changeRealSelectBox(newValue, newLabel, true);
    });

    styleSelectOption.addEventListener("mousedown", function (ev) {
      ev.preventDefault();
      ev.stopPropagation();
    });

    // Tick and highlight the option that's currently in use
    if (styleSelectOption.dataset.value === realSelect.value) {
      highlightedOptionIndex = index;
      styleSelectOption.classList.add("ticked");
      styleSelectOption.classList.add("highlighted");
    }

    // Important: we can't use ':hover' as the keyboard and default value can also set the highlight
    styleSelectOption.addEventListener("mouseover", function (ev) {
      styleSelectOption.parentNode.childNodes.forEach(function (
        sibling,
        index
      ) {
        if (sibling === ev.target) {
          sibling.classList.add("highlighted");
          highlightedOptionIndex = index;
        } else {
          sibling.classList.remove("highlighted");
        }
      });
    });

    styleSelectOption.addEventListener("mouseout", function (ev) {
      styleSelectOption.parentNode.childNodes.forEach(function (
        sibling,
        index
      ) {
        sibling.classList.remove("highlighted");
      });
    });
  });

  var closeAllStyleSelects = function (exception) {
    queryAll(".style-select").forEach(function (styleSelectEl) {
      if (styleSelectEl !== exception) {
        styleSelectEl.classList.remove("open");
        setAriaProp(selectedOption, "expanded", false);
      }
    });
  };

  var toggleStyledSelect = function (styledSelectBox) {
    if (!styledSelectBox.classList.contains("open")) {
      // If we're closed and about to open, close other style selects on the page
      closeAllStyleSelects(styledSelectBox);
      setAriaProp(selectedOption, "expanded", true);
    } else {
      setAriaProp(selectedOption, "expanded", false);
    }
    // Then toggle open/close
    styledSelectBox.classList.toggle("open");
  };

  // When a styled select box is clicked
  var styledSelectedOption = query(
    '.style-select[data-ss-uuid="' + uuid + '"] .ss-selected-option'
  );
  styledSelectedOption.addEventListener("click", function (ev) {
    ev.preventDefault();
    ev.stopPropagation();
    toggleStyledSelect(ev.target.parentNode);
  });

  // Keyboard handling
  styledSelectedOption.addEventListener("keydown", function (ev) {
    var styledSelectBox = ev.target.parentNode;

    switch (ev.keyCode) {
      case KEYCODES.SPACE:
        ev.preventDefault();
        // Space shows and hides styles select boxes
        toggleStyledSelect(styledSelectBox);
        break;
      case KEYCODES.TAB:
        if (!styledSelectBox.classList.contains("open")) {
          ev.preventDefault();
          toggleStyledSelect(styledSelectBox);
        }
        break;
      case KEYCODES.DOWN:
      case KEYCODES.UP:
        // Move the highlight up and down
        // If style select is already open, these should change what the highlighted option is
        if (ev.keyCode === KEYCODES.UP) {
          // Up arrow moves earlier in list
          if (highlightedOptionIndex !== 0) {
            highlightedOptionIndex = highlightedOptionIndex - 1;
          }
        } else {
          // Down arrow moves later in list
          if (highlightedOptionIndex < highlightedOptionIndexMax) {
            highlightedOptionIndex = highlightedOptionIndex + 1;
          }
        }
        styleSelectOptions.forEach(function (option, index) {
          if (index === highlightedOptionIndex) {
            option.classList.add("highlighted");
          } else {
            option.classList.remove("highlighted");
          }
        });
        ev.preventDefault();
        ev.stopPropagation();
        break;
      // User has picked an item from the keyboard
      case KEYCODES.ESCAPE:
        close();
      case KEYCODES.ENTER:
        var highlightedOption =
            styledSelectedOption.parentNode.querySelectorAll(".ss-option")[
              highlightedOptionIndex
            ],
          newValue = highlightedOption.dataset.value,
          newLabel = highlightedOption.textContent;

        changeRealSelectBox(newValue, newLabel, true, ev);
        if (ev.target.closest(".forum-input-row") != null) {
          ev.target
            .closest(".forum-input-row")
            .nextElementSibling.querySelector("input")
            .focus();
          ev.target
            .closest(".forum-input-row")
            .nextElementSibling.setAttribute("tabindex", "1");
        }

        ev.preventDefault();
        // ev.stopPropagation();
        break;
    }
  });

  // Clicking outside of the styled select box closes any open styled select boxes
  query("body").addEventListener("click", function (ev) {
    if (!isAncestorOf(ev.target, ".style-select", true)) {
      closeAllStyleSelects();
    }
  });
}

export default { init };
