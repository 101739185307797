import React, { useEffect, useState } from "react";
import SeatsData from "../data/seats.json";
import MemberPopup from "../components/MemberPopup.jsx";
import { store, useGlobalState } from "state-pool";
import Loading from "../components/Loading";
import PrintLayoutChamberOverview from "../components/PrintlayoutChamberOverview";

export default function Seats(props) {
  // const [renderItems, setRenderItems] = useState([]);
  const [appdata] = useGlobalState("appdata");
  const [sortedLegendItems, setSortedLegendItems] = useState([]);

  useEffect(() => {
    setSortedLegendItems(sortLegendItems());
  }, []);

  const sortLegendItems = () => {
    const legendSortedList = [];
    if (appdata.legend !== undefined) {
      appdata.legend.item.forEach((item, index) => {
        item.index = index;
        if (item.index === 0) {
          legendSortedList.push(item);
        } else if (item.index === 2) {
          item.index = 1;
          legendSortedList.push(item);
        } else if (item.index === 1) {
          item.index = 2;
          legendSortedList.push(item);
        } else if (item.index === 3) {
          legendSortedList.push(item);
        }
      });
      legendSortedList.sort(function (a, b) {
        return a.index - b.index;
      });
    }
    return legendSortedList;
  };

  if (appdata === null) {
    return (
      <div className="container">
        <Loading />
      </div>
    );
  } else if (appdata) {
    return (
      <div>
        <div className="member-overview__container">
          {SeatsData.map((item, index) => (
            <MemberPopup
              key={index}
              positionY={item.y}
              positionX={item.x}
              member={appdata.seats.seat.find(
                (seat) => seat.seatNumber === item.id
              )}
              isClickable={true}
              useFilters={true}
              isVoteResult={props.isVoteResult}
            />
          ))}
        </div>
        <div className="member-overview__container printlayout" tabIndex="-1">
          {SeatsData.map((item, index) => (
            <PrintLayoutChamberOverview
              key={index}
              positionY={item.y}
              positionX={item.x}
              member={appdata.seats.seat.find(
                (seat) => seat.seatNumber === item.id
              )}
              isClickable={true}
              useFilters={true}
              isVoteResult={props.isVoteResult}
            />
          ))}
        </div>
        <div
          className={`voting__types mobile-view
							${appdata.legend !== undefined ? "" : "hidden"}`}
        >
          {sortedLegendItems !== undefined &&
            sortedLegendItems.length > 0 &&
            sortedLegendItems.map((item, index) => (
              <div key={index} className="voting__types--item">
                <span
                  className="voting__dot"
                  style={{
                    backgroundColor: item.dotColor,
                    borderColor: item.strokeColor,
                  }}
                ></span>
                <span>{item.text}</span>
              </div>
            ))}
        </div>
      </div>
    );
  }
}
