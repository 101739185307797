import React, { useState, useEffect, useLayoutEffect } from "react";
import { HashRouter as Router, Switch, Route, NavLink } from "react-router-dom";
import { Redirect } from "react-router-dom";
import Overview from "./containers/overview";
import DataVisualization from "./containers/datavisualization";
import { useGlobalState } from "state-pool";

export default function Routes() {
  const [appdata] = useGlobalState("appdata");

  const [chosenParties] = useGlobalState("chosenParties");
  const [detailedMemberList, setDetailedMemberList] =
    useGlobalState("detailedMemberList");
  const [chosenMember] = useGlobalState("chosenMember");
  const [memberFilter, setMemberFilter, updateMemberFilter] =
    useGlobalState("memberFilter");
  const [committeeFilter, setCommitteeFilter, updateCommitteeFilter] =
    useGlobalState("committeeFilter");
  const [constituencyFilter, setConstituencyFilter, updateConstituencyFilter] =
    useGlobalState("constituencyFilter");
  const [
    ministerialTitleFilter,
    setMinisterialTitleFilter,
    updateMinisterialTitleFilter,
  ] = useGlobalState("ministerialTitleFilter");
  const [
    rapporteurshipFilter,
    setRapporteurshipFilter,
    updateRapporteurshipFilter,
  ] = useGlobalState("rapporteurshipFilter");
  const [facetedFilters, setFacetedFilters] = useGlobalState("facetedFilters");
  const [isMinister, setIsMinister] = useGlobalState("isMinister");
  const [isAllMinister, setIsAllMinister] = useGlobalState("isAllMinister");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [previousUrl, setPreviousUrl] = useState(
    encodeURIComponent(window.location.href)
  );

  const metaOgSiteName = document.querySelector('meta[property="og:site_name"]');
  const [overviewRouteTitle] = useGlobalState("overviewdocumenttitle");
  const [datavisualizationRouteTitle] = useGlobalState("datavisualizationdocumenttitle");

  const getPageTitle = (routeTitle) => {
    if (metaOgSiteName && metaOgSiteName.hasAttribute("content"))
      return routeTitle === ""
        ? `${metaOgSiteName.content}`
        : `${routeTitle} / ${metaOgSiteName.content}`;
  }
  
  const overviewDocumentTitle = getPageTitle(overviewRouteTitle);
  const datavisualizationDocumentTitle = getPageTitle(datavisualizationRouteTitle);


  const $ = window.$;

  useEffect(() => {
    if (appdata.popup) {
      setIsModalVisible(true);
    }
  }, []);

  const setEmailHref = () => {
    const urlToReplace = previousUrl;
    const currentUrl = encodeURIComponent(window.location.href);
    const emailElement = document.querySelector(".content-action-mail");
    if (!emailElement) return;
    const href = emailElement.getAttribute("href");
    emailElement.setAttribute("href", href.replace(urlToReplace, currentUrl));
    setPreviousUrl(currentUrl);
  };

  function resetFilters() {
    facetedFilters.forEach((element) => {
      element.name = [];
    });
    setFacetedFilters([]);
    setIsAllMinister(false);
    setIsMinister(false);
    if (chosenParties.length === 0) {
      setDetailedMemberList([]);
    }

    if (chosenMember && chosenParties.length === 0) {
      const memberToPush = appdata.seats.seat.filter(
        (seat) => chosenMember === seat.seatNumber
      );
      setDetailedMemberList(memberToPush);
    }

    // Update all chosen selects
    updateMemberFilter((filter) => {
      filter.value = 0;
    });

    $("#Medlemmer").val(0).trigger("chosen:updated");

    updateCommitteeFilter((filter) => {
      filter.value = 0;
    });

    $("#Udvalg").val(0).trigger("chosen:updated");
    updateConstituencyFilter((filter) => {
      filter.value = 0;
    });
    $("#Valgkreds").val(0).trigger("chosen:updated");

    updateMinisterialTitleFilter((filter) => {
      filter.value = 0;
    });
    $("#Regeringen").val(0).trigger("chosen:updated");

    updateRapporteurshipFilter((filter) => {
      filter.value = 0;
    });
    $("#Ordførerskab").val(0).trigger("chosen:updated");
  }

  return (
    <div>
      {appdata && (
        <Router>
          <div className="row">
            <ul className="routes">
              <li
                key={appdata.buttons.button[0].label}
                className={appdata.buttons.button[0].buttonType + "-link"}
              >
                <NavLink
                  activeClassName="active"
                  to={"/" + appdata.buttons.button[0].label}
                >
                  {appdata.buttons.button[0].label}
                </NavLink>
              </li>
              <li
                key={appdata.buttons.button[1].label}
                className={appdata.buttons.button[1].buttonType + "-link"}
              >
                <NavLink
                  activeClassName="active"
                  to={"/" + appdata.buttons.button[1].label}
                  onClick={(e) => resetFilters()}
                >
                  {appdata.buttons.button[1].label}
                </NavLink>
              </li>
              <li className={appdata.buttons.button[2].buttonType + "-link"}>
                <a href={appdata.buttons.button[2].link}>
                  {appdata.buttons.button[2].label}{" "}
                  <span className="icon-pil-spinkel-hoejre"></span>
                </a>
              </li>
            </ul>
            <Switch>
              <Route path={"/" + appdata.buttons.button[0].label}>
                <Overview
                  modalDependencies={{ isModalVisible, setIsModalVisible }}
                  setEmailHref={setEmailHref}
                  overviewDocumentTitle={overviewDocumentTitle}
                />
              </Route>
              <Route path={"/" + appdata.buttons.button[1].label}>
                <DataVisualization
                  modalDependencies={{ isModalVisible, setIsModalVisible }}
                  setEmailHref={setEmailHref}
                  datavisualizationDocumentTitle={datavisualizationDocumentTitle}
                />
              </Route>
              <Redirect to={"/" + appdata.buttons.button[0].label} />
            </Switch>
          </div>
        </Router>
      )}
    </div>
  );
}
