import React from "react";

const ButtonNext = ({ onStepChange, isValid, dictionary }) => (
  <div className="row">
    <div className="col-sm-6 col-sm-offset-6">
      <button
        type="button"
        disabled={!isValid}
        onClick={onStepChange}
        aria-label={dictionary.Labels.ButtonNext}
        className="button button--arrow pull-right"
      >
        <span>{dictionary.Labels.ButtonNext}</span>
      </button>
    </div>
  </div>
);

export default ButtonNext;
