import { store, useGlobalState } from "state-pool";
import SingleFacetedFilter from "./SingleFacetedFilter";

export default function FacetedFilters(props) {
  const [facetedFilters, setFacetedFilters] = useGlobalState("facetedFilters");

  return (
    <div className="faceted-filter">
      {facetedFilters.map((item, index) => (
        <SingleFacetedFilter key={index} filter={item} index={index} />
      ))}
    </div>
  );
}
