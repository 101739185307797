import React from "react";
import ReactDOM from "react-dom";
import { HashRouter as Router } from "react-router-dom";

import CreatePost from "./create-post";

const CreatePostElement = document.getElementById("forum-create-post");

if (CreatePostElement) {
  ReactDOM.render(
    <React.StrictMode>
      <Router>
        <CreatePost />
      </Router>
    </React.StrictMode>,
    CreatePostElement
  );
}
