import React, { Fragment, useState } from "react";
import ReactCrop from "react-image-crop";

import "react-image-crop/dist/ReactCrop.css";

const ImageCropper = (props) => {
  const { imgSrc, setCroppedImage, closeModal, setImgSrc, dictionary } = props;

  const [image, setImage] = useState("");
  const [crop, setCrop] = useState({
    unit: "%",
    width: 90,
    aspect: 16 / 9,
    y: 8.5,
    x: 5.5
  });

  const closeAndResetSrcImg = () => {
    closeModal();
    setImgSrc("");
  };

  const getCroppedImg = (event) => {
    event.preventDefault();
    try {
      const canvas = document.createElement("canvas");
      const scaleX = image.naturalWidth / image.width;
      const scaleY = image.naturalHeight / image.height;
      canvas.width = 1040;
      canvas.height = 585;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        canvas.width,
        canvas.height
      );

      const base64Image = canvas.toDataURL("image/jpeg", 1);

      setCroppedImage(base64Image);
      closeModal();
    } catch (e) {}
  };

  return (
    <Fragment>
      <p>{dictionary.Modal.Crop.Title}</p>
      <ReactCrop
        src={imgSrc}
        crop={crop}
        onImageLoaded={setImage}
        onChange={setCrop}
        crossorigin="anonymous"
        keepSelection={true}
      />
      <div className="file-modal__image-crop-buttons">
        <button type="button" className="button--nostyling" onClick={closeAndResetSrcImg}>
          {dictionary.Modal.Crop.CancelButton}
        </button>
        <button type="button" className="button" onClick={getCroppedImg}>
          {dictionary.Modal.Crop.SaveButton}
        </button>
      </div>
    </Fragment>
  );
};

export default ImageCropper;
