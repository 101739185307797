import React from "react";

import { useGlobalState } from "state-pool";

const MemberDetailedVoteResult = () => {
  const [chosenVoteResultMember] = useGlobalState("chosenVoteResultMember");
  const member = chosenVoteResultMember;

  return (
    member && (
      <div
        id="chosen-member-mobile"
        className="row member mobile member-detailed-vote-result"
      >
        <div className="mobile__container">
          <div className="col-sm-2 member__img-container">
            <img
              src={member.picture}
              alt={member.personId}
              className="member__img"
            />
          </div>
          <div className="col-sm-9 member__info--content">
            <div className="member__info">
              <p>
                {member.firstName} {member.lastName}{" "}
              </p>
              <p>
                <span>{member.party}</span>
              </p>
              <p>
                <span>{member.title3}</span>
              </p>
            </div>
          </div>
          <div className="col-sm-1"></div>
        </div>
      </div>
    )
  );
};

export default MemberDetailedVoteResult;
