import React, { useEffect, useState, useRef, useLayoutEffect } from "react";
import { useHistory, useParams } from "react-router";
import { useGlobalState } from "state-pool";
import * as helpers from "../shared/helpers";

export default function FilterDropdown(props) {
  const [internalState, setInternalState] = useState(props.filteredItem);
  const [filter, setFilter] = useGlobalState(props.filterName);
  const history = useHistory();
  const [appdata] = useGlobalState("appdata");
  const [isFiltered, setIsFiltered] = useGlobalState("isFiltered");
  const [isFromDropdownFilter, setIsFromDropdownFilter] = useGlobalState(
    "isFromDropdownFilter"
  );
  const [detailedMemberList, setDetailedMemberList, updateDetailedMemberList] =
    useGlobalState("detailedMemberList");
  const [
    dataVisualizationDetailedMemberList,
    setDataVisualizationDetailedMemberList,
  ] = useGlobalState("dataVisualizationDetailedMemberList");
  const [chosenParties, setChosenParties] = useGlobalState("chosenParties");
  const [chosenMember, setChosenMember] = useGlobalState("chosenMember");
  const [memberFilter, setMemberFilter, updateMemberFilter] =
    useGlobalState("memberFilter");
  const [committeeFilter, setCommitteeFilter, updateCommitteeFilter] =
    useGlobalState("committeeFilter");
  const [constituencyFilter, setConstituencyFilter, updateConstituencyFilter] =
    useGlobalState("constituencyFilter");
  const [isAllMinister, setIsAllMinister] = useGlobalState("isAllMinister");
  const [
    ministerialTitleFilter,
    setMinisterialTitleFilter,
    updateMinisterialTitleFilter,
  ] = useGlobalState("ministerialTitleFilter");
  const [
    rapporteurshipFilter,
    setRapporteurshipFilter,
    updateRapporteurshipFilter,
  ] = useGlobalState("rapporteurshipFilter");
  const [date] = useGlobalState("date");

  const isFirstRender = useRef(true);
  const [selected, setSelected] = useState();

  let dropdownIds = {};
  const { members, committees, constituencies, ministerialTitles, spokesmen } =
    appdata;
  dropdownIds.membersId = members !== undefined ? members.label : "Medlemmer";
  dropdownIds.committeesId =
    committees !== undefined ? committees.label : "Udvalg";
  dropdownIds.constituenciesId =
    constituencies !== undefined ? constituencies.label : "Valgkreds";
  dropdownIds.ministerialTitlesId =
    ministerialTitles !== undefined ? ministerialTitles.label : "Regeringen";
  dropdownIds.spokesmenId =
    spokesmen !== undefined ? spokesmen.label : "Ordførerskab";

  const dropdowns = [
    { id: dropdownIds.membersId, list: updateMemberFilter },
    { id: dropdownIds.committeesId, list: updateCommitteeFilter },
    { id: dropdownIds.constituenciesId, list: updateConstituencyFilter },
    { id: dropdownIds.ministerialTitlesId, list: updateMinisterialTitleFilter },
    { id: dropdownIds.spokesmenId, list: updateRapporteurshipFilter },
  ];

  useLayoutEffect(() => {
    $(
      ".chamber-overview__filters .chosen-collapsed:not(.chosen-disabled) .chosen-single b"
    ).addClass("icon-pil-spinkel-hoejre");
    addAriaLabel();
  });

  const addAriaLabel = () => {
    dropdowns.forEach((label) => {
      var searchInput = $('#' + label.id).next().find('.search-input');
      var labelText = label.id.toLowerCase();
      var labelName = "Søg efter " + labelText;

      searchInput.attr("aria-label", labelName);          
    });
  }

  useEffect(() => {
    const $ = window.$;

    $(".chamber-overview__filters")
      .find("#" + props.label)
      .chosen({
        width: "100%",
        allow_single_deselect: true,
        //disable_search: disableSearch, // true/false is set by editor in SC
        search_contains: true,
        include_group_label_in_selected: true,
      })
      .change(function (e) {
        setSelected(e);
      });
  }, []);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    setIsFiltered(true);
    handleReset();

    //closed keyboard after selecting on ipad
    $('.search-input').each(function(i, item) {
      $(item).blur();
    });

    handleDropdownsReset(selected.target.id);
    handleSetFilter(selected.target.value);
    handlePushToDetailedMemberList();
  }, [selected]);

  const handleFilterQuery = (value) => {
    history.push({
      search: helpers.updateUrlParameter(
        `?date=${date.replace(/-/g, "")}`,
        props.filterName,
        value
      ),
    });
  };

  const handleDropdownsReset = (dropdownId) => {
    if (dropdownId === props.previousDropdownId) return;
    const dropdownElement = $(`#${props.previousDropdownId}`);
    dropdownElement.val(0).trigger("chosen:updated");
    dropdowns.findIndex((dropdown) =>
      dropdown.id === props.previousDropdownId
        ? dropdown.list((filter) => {
            filter.value = 0;
          })
        : ""
    );

    props.setPreviousDropdownId(dropdownId);
  };

  const handleReset = () => {
    setChosenParties([]);
    setDetailedMemberList([]);
    setIsAllMinister(false);
    setChosenMember(null);
    setDataVisualizationDetailedMemberList([]);
  };

  const handleSetFilter = (value) => {
    setFilter({ value: value, key: props.filterKey });
    handleFilterQuery(value);
  };

  const handlePushToDetailedMemberList = () => {
    setIsFromDropdownFilter(true);
  };

  const isFilterDisabled = (data) => {
    if (!Array.isArray(data) || !data.length) return true;
    return false;
  };

  return (
    <div>
      <label className="page-reference__label" htmlFor={props.id}>
        {props.label}
      </label>
      <select
        id={props.label}
        data-disable-search="true"
        datavaluefield="Value"
        data-max-height="6"
        datatextfield="Name"
        className="form-control columnlookup-dropdown content-action--session-select is-disabled-search"
        defaultValue={props.defaultValue}
        disabled={isFilterDisabled(props.data, props.filterName)}
      >
        <option value="0">{props.noSelection}</option>
        {props.allSeelction !== undefined && (
          <option value="999">{props.allSeelction}</option>
        )}
        {props.data && props.data.length
          ? props.data.map((item, index) => (
              <option value={item[props.valueKey]} key={index}>
                {item[props.labelKey]}
              </option>
            ))
          : ""}
      </select>
    </div>
  );
}
