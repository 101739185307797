import Intro from "../components/Intro";
import Parties from "../components/Parties";
import ChartSex from "../components/ChartSex";
import ChartAge from "../components/ChartAge";
import ChartYears from "../components/ChartYears";
import ChartConstituency from "../components/ChartConstituency";
import ChartMandates from "../components/ChartMandates";
import DatePicker from "../components/Date_picker";
import Reset from "../components/Reset_button";
import Modal from "../components/Modal";
import {
  HashRouter as Router,
  Switch,
  Route,
  NavLink,
  useRouteMatch,
  useLocation,
} from "react-router-dom";
import { Redirect } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useGlobalState } from "state-pool";
import * as helpers from "../shared/helpers";
import Loading from "../components/Loading";
import { useEffect, useLayoutEffect, useState } from "react";
import MemberListDataVisualization from "../components/MemberListDataVisualization";

export default function DataVisualization(props) {
  const [appdata, setAppData] = useGlobalState("appdata");
  const history = useHistory();
  const [date, setDate] = useGlobalState("date");
  let defaultSearchDate = helpers.convertDate(date);
  let { path, url } = useRouteMatch();
  const title = useGlobalState("datavisualizationTitle");
  const manchet = useGlobalState("datavisualizationManchet");
  const [chosenParties] = useGlobalState("chosenParties");
  const [chosenMember] = useGlobalState("chosenMember");
  const [datePickerLabel, setDatePickerLabel] = useState("");
  const [isYearChartSelected, setIsYearChartSelected] = useState(false);
  const location = useLocation();
  const dataTitle = location.pathname.split("/").pop();
  const printDate =
    appdata.date.searchDate.substring(8, 10) +
    "-" +
    appdata.date.searchDate.substring(5, 7) +
    "-" +
    appdata.date.searchDate.substring(0, 4);
  const $ = window.$;

  const onDateChange = (selecteddate) => {
    if (date !== selecteddate) {
      setDate(selecteddate);
      setAppData(null);

      if (!appdata) {
        helpers.loadDataForDate(selecteddate).then((response) => {
          if (response.statusText === "success") {
            setAppData(response.data);
          } else {
            alert("Noget gik galt");
          }
        });
      }
    }
  };

  const handleDateQuery = (queryDate) => {
    history.push({
      search: helpers.updateUrlParameter(
        history.location.search,
        "date",
        queryDate.replace(/-/g, "")
      ),
    });
  };

  const handleCloseModal = (e) => {
    e.preventDefault();
    props.modalDependencies.setIsModalVisible(false);
    handleDateQuery(appdata.date.searchDate);
    setDate(appdata.date.searchDate.replace(/-/g, ""));
    $("#content").removeClass("noScroll");
  };

  useLayoutEffect(() => {
    document.title = props.datavisualizationDocumentTitle;
    addDataVisualizationClass();
    setupSecondaryServiceMenuPrint();
  });

  useEffect(() => {
    props.setEmailHref();
  }, [history.location, history.location.search]);

  useEffect(() => {
    setIsYearChartSelected(location.pathname.includes("År i Folketinget"));
  }, [location.pathname]);

  useEffect(() => {
    let parts = $(window.location).attr("href").split("/"),
      last_part =
        parts[parts.length - 1] !== ""
          ? parts[parts.length - 1]
          : parts[parts.length - 2];

    if (
      decodeURIComponent(last_part).includes("År i Folketinget") &&
      appdata.datagroups.datagroup[3].enabled === "0"
    ) {
      setDatePickerLabel(appdata.dateSelection.labelAarIFolketinget);
      if (
        date < appdata.dateSelection.firstAarIFolketingetDate.replace(/-/g, "")
      ) {
        history.replace({
          pathname: encodeURIComponent(
            decodeURIComponent(history.location.pathname).replace(
              "År i Folketinget",
              "Køn"
            )
          ),
          search: helpers.updateUrlParameter(
            history.location.search,
            "date",
            appdata.date.searchDate.replace(/-/g, "")
          ),
        });
      }
    } else {
      setDatePickerLabel(appdata.dateSelection.label);
    }
  });

  const setupSecondaryServiceMenuPrint = () => {
    const dataPrintElement = document.getElementsByClassName('data-print')[0];
    const overviewPrintElement = document.getElementsByClassName('overview-print')[0];
    if(dataPrintElement){
      dataPrintElement.style.display = "inline";
    }
    if(overviewPrintElement){
      overviewPrintElement.style.display = "none";
    }
  }

  const addDataVisualizationClass = () => {
    const bodyElement = document.querySelector("body");
    if (bodyElement) {
      bodyElement.classList.add("data-visualization-route");
    }
  };

  if (appdata === null) {
    return (
      <div className="container">
        <Loading />
      </div>
    );
  } else if (appdata) {
    return (
      <div className="container data-visualization">
        <div
          id="overlay"
          className={`page-overlay ${
            props.modalDependencies.isModalVisible && "show-overlay"
          } `}
        ></div>
        <Modal
          close={handleCloseModal}
          isVisible={props.modalDependencies.isModalVisible}
          data={appdata.popup}
        />
        <div className="row intro-container">
          <div className="col-sm-8">
            <Intro title={title[0]} description={manchet[0]} />
          </div>
        </div>
        <div className="row data-visualization-filters">
          <div className="col-xs-12 col-sm-6 desktop-no-padding">
            {
              <DatePicker
                key={`datepicker-${isYearChartSelected}`}
                label={datePickerLabel}
                defaultDate={appdata.date.searchDate}
                maxDate={appdata.dateSelection.maxDate}
                minDate={appdata.dateSelection.minDate}
                date={date}
                setDate={onDateChange}
                nonDates={appdata.nonSelectableDates.nonDates}
                dateFeedbackLabels={appdata.dateFeedbackLabels}
                isYearChartSelected={isYearChartSelected}
                yearChartMinDate={
                  appdata.dateSelection.firstAarIFolketingetDate
                }
              />
            }
          </div>
        </div>
        <div id="download-pdf" className="row">
          <div className="col-sm-12 contentProps">
            <Router>
              <div className="row">
                <ul className="routes routes--small">
                  {appdata.datagroups.datagroup.map((datagroup, i) => {
                    const isDisabled = datagroup.enabled === "0";
                    return (
                      <li key={datagroup.label}>
                        <NavLink
                          exact
                          activeClassName="active"
                          to={`${url}/${datagroup.label}`}
                          key={datagroup.label}
                          className={isDisabled ? "route-disabled" : ""}
                          disabled={isDisabled}
                          tabIndex={isDisabled ? -1 : ""}
                        >
                          {datagroup.label}
                        </NavLink>
                      </li>
                    );
                  })}
                </ul>
                <div className="row row__content data-visualization-content">
                  <div className="data-visualization-intro">
                    <div className="data-visualization-intro-title">
                      <div className='data-visualization-intro-title'>{appdata.otherLabels.otherLabel.filter((label) => (label.id === 'printHeader'))[0].label}</div>
                    </div>
                    <div className="data-visualization-intro-date">
                      {printDate}
                    </div>
                    <div className="data-visualization-intro-selected">
                      {dataTitle}
                    </div>
                  </div>
                  <div className="col-xs-12 col-md-2 parties-container">
                    <Parties disabled="true" parties={appdata.parties.party} />
                  </div>
                  <div
                    className="col-xs-12 col-md-9 data-visualization-container"
                    style={{ display: "flex", alignItems: "flex-end" }}
                  >
                    <Switch>
                      <Route
                        exact
                        path={`${path}/${appdata.datagroups.datagroup[0].label}`}
                      >
                        <ChartSex groupdata={appdata.datagroups.datagroup[0]} />
                      </Route>
                      <Route
                        exact
                        path={`${path}/${appdata.datagroups.datagroup[1].label}`}
                      >
                        <ChartAge groupdata={appdata.datagroups.datagroup[1]} />
                      </Route>
                      <Route
                        exact
                        path={`${path}/${appdata.datagroups.datagroup[2].label}`}
                      >
                        <ChartConstituency
                          groupdata={appdata.constituencies.constituency}
                        />
                      </Route>
                      <Route
                        exact
                        path={`${path}/${appdata.datagroups.datagroup[3].label}`}
                      >
                        <ChartYears
                          groupdata={appdata.datagroups.datagroup[3]}
                        />
                      </Route>
                      <Route
                        exact
                        path={`${path}/${appdata.datagroups.datagroup[4].label}`}
                      >
                        <ChartMandates groupdata={appdata.parties.party} />
                      </Route>
                      <Redirect
                        to={`${path}/${appdata.datagroups.datagroup[0].label}`}
                      />
                    </Switch>
                  </div>
                  <div className="col-md-1 render-seats-reset">
                    {(chosenParties.length > 0 || chosenMember) && <Reset />}
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <MemberListDataVisualization />
                  </div>
                </div>
              </div>
            </Router>
          </div>
        </div>
      </div>
    );
  }
}
