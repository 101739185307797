import { useEffect, useState } from "react";
import { store, useGlobalState } from "state-pool";
import * as helpers from "../shared/helpers";

const PrintlayoutChamberOverview = (props) => {
  // const [chosenParty, setChosenParty, updateChosenParty] = useGlobalState('chosenParty');
  const [chosenParties, setChosenParties, updateChosenParties] =
    useGlobalState("chosenParties");
  const [detailedMemberList, setDetailedMemberList, updateDetailedMemberList] =
    useGlobalState("detailedMemberList");
  const [chosenMember, setChosenMember] = useGlobalState("chosenMember");
  const [memberFilter, setMemberFilter, updateMemberFilter] =
    useGlobalState("memberFilter");
  const [committeeFilter, setCommitteeFilter, updateCommitteeFilter] =
    useGlobalState("committeeFilter");
  const [constituencyFilter, setConstituencyFilter, updateConstituencyFilter] =
    useGlobalState("constituencyFilter");
  const [
    ministerialTitleFilter,
    setMinisterialTitleFilter,
    updateMinisterialTitleFilter,
  ] = useGlobalState("ministerialTitleFilter");
  const [
    rapporteurshipFilter,
    setRapporteurshipFilter,
    updateRapporteurshipFilter,
  ] = useGlobalState("rapporteurshipFilter");

  const [appdata] = useGlobalState("appdata");
  const [isDisabled, setIsDisabled] = useState(false);
  const [originalDetailedMemberList, setOriginalDetailedMemberList] = useState(
    []
  );
  const member = props.member;
  let memberHasInfo = false;
  memberHasInfo = member !== undefined && member.firstName ? true : false;
  const [facetedFilters, setFacetedFilters] = useGlobalState("facetedFilters");
  const [isMinister, setIsMinister] = useGlobalState("isMinister");
  const [isAllMinister, setIsAllMinister] = useGlobalState("isAllMinister");
  const [isFiltered] = useGlobalState("isFiltered");
  const [isFromDropdownFilter, setIsFromDropdownFilter] = useGlobalState(
    "isFromDropdownFilter"
  );

  // temporary for printlayout
  // right center content
  const rightCenter = ["102", "133", "147", "187", "188", "194"];

  // left center content
  const arrayCenter1 = Array.from({ length: 5 }, (_, i) => i + 1);
  const arrayCenter2 = Array.from({ length: 6 }, (_, i) => i + 8);
  const arrayCenter3 = Array.from({ length: 7 }, (_, i) => i + 19);
  const arrayCenter4 = Array.from({ length: 7 }, (_, i) => i + 34);
  const arrayCenter5 = Array.from({ length: 7 }, (_, i) => i + 52);
  const arrayCenter6 = Array.from({ length: 3 }, (_, i) => i + 58);
  var leftCenter = arrayCenter1.concat(
    arrayCenter2,
    arrayCenter3,
    arrayCenter4,
    arrayCenter5,
    arrayCenter6
  );
  var leftCenter = leftCenter.toString().split(",");

  // below
  const arrayBelow1 = [7, 18, 32, 33, 167, 159];
  const arrayBelow2 = Array.from({ length: 3 }, (_, i) => i + 49);
  const arrayBelow3 = Array.from({ length: 4 }, (_, i) => i + 71);
  const arrayBelow4 = Array.from({ length: 2 }, (_, i) => i + 87);
  const arrayBelow5 = Array.from({ length: 2 }, (_, i) => i + 148);
  const arrayBelow6 = Array.from({ length: 2 }, (_, i) => i + 134);
  const arrayBelow7 = Array.from({ length: 2 }, (_, i) => i + 117);
  const arrayBelow8 = Array.from({ length: 2 }, (_, i) => i + 103);

  var arrayBelow = arrayBelow1.concat(
    arrayBelow2,
    arrayBelow3,
    arrayBelow4,
    arrayBelow5,
    arrayBelow6,
    arrayBelow7,
    arrayBelow8
  );
  var arrayBelow = arrayBelow.toString().split(",");

  // leaning right
  const arrayRight0 = [146, 132];
  const arrayRight1 = Array.from({ length: 3 }, (_, i) => i + 168);
  const arrayRight2 = Array.from({ length: 4 }, (_, i) => i + 162);
  const arrayRight3 = Array.from({ length: 5 }, (_, i) => i + 153);
  const arrayRight4 = Array.from({ length: 5 }, (_, i) => i + 141);
  const arrayRight5 = Array.from({ length: 7 }, (_, i) => i + 125);
  const arrayRight6 = Array.from({ length: 3 }, (_, i) => i + 114);
  const arrayRight7 = Array.from({ length: 2 }, (_, i) => i + 100);
  var arrayRight = arrayRight1.concat(
    arrayRight0,
    arrayRight2,
    arrayRight3,
    arrayRight4,
    arrayRight5,
    arrayRight6,
    arrayRight7
  );
  var arrayRight = arrayRight.toString().split(",");

  // below right
  const arrayBR1 = [160, 161];
  const arrayBR2 = Array.from({ length: 3 }, (_, i) => i + 150);
  const arrayBR3 = Array.from({ length: 5 }, (_, i) => i + 136);
  const arrayBR4 = Array.from({ length: 6 }, (_, i) => i + 119);
  const arrayBR5 = Array.from({ length: 9 }, (_, i) => i + 105);
  const arrayBR6 = Array.from({ length: 3 }, (_, i) => i + 97);
  var arrayBelowRight = arrayBR1.concat(
    arrayBR2,
    arrayBR3,
    arrayBR4,
    arrayBR5,
    arrayBR6
  );
  var arrayBelowRight = arrayBelowRight.toString().split(",");

  // leaning left
  const arrayLeft1 = [6];
  const arrayLeft2 = Array.from({ length: 2 }, (_, i) => i + 14);
  const arrayLeft3 = Array.from({ length: 3 }, (_, i) => i + 26);
  const arrayLeft4 = Array.from({ length: 4 }, (_, i) => i + 41);
  const arrayLeft5 = Array.from({ length: 5 }, (_, i) => i + 61);
  const arrayLeft6 = Array.from({ length: 9 }, (_, i) => i + 75);
  const arrayLeft7 = Array.from({ length: 8 }, (_, i) => i + 89);

  var arrayLeft = arrayLeft1.concat(
    arrayLeft2,
    arrayLeft3,
    arrayLeft4,
    arrayLeft5,
    arrayLeft6,
    arrayLeft7
  );
  var arrayLeft = arrayLeft.toString().split(",");

  // below left
  const arrayBL1 = [16, 17, 84, 85, 86];
  const arrayBL2 = Array.from({ length: 3 }, (_, i) => i + 29);
  const arrayBL3 = Array.from({ length: 4 }, (_, i) => i + 45);
  const arrayBL4 = Array.from({ length: 5 }, (_, i) => i + 66);
  var arrayBelowLeft = arrayBL1.concat(arrayBL2, arrayBL3, arrayBL4);
  var arrayBelowLeft = arrayBelowLeft.toString().split(",");

  const $ = window.$;
  
  const memberTitle = (member) => {

    if (member.title4 &&
      member.title4 !== "Medlem af Folketinget" &&
      member.title4 !== "Medlem af Ungdomsparlamentet" &&
      member.title4 !== "Deltager i Nordisk Råd") {
        return member.title4;
    }
    
    if (
      member.title3 &&
      member.title3 !== "Medlem af Folketinget" &&
      member.title3 !== "Medlem af Ungdomsparlamentet" &&
      member.title3 !== "Deltager i Nordisk Råd"
    ) {
      return member.title3;
    }

    if (member.firstName !== undefined) {
      return member.firstName + " " + member.lastName;
    } 
    
    return "";
  }

  if (member !== undefined) {
    const memberName =
      member.firstName !== undefined
        ? member.firstName + " " + member.lastName
        : "";
    const posClass = "seat-" + member.seatNumber;
    return (
      <div
        tabIndex={
          chosenParties.filter(
            (chosenParty) => chosenParty !== member.partyAbbreviation
          ).length >
            chosenParties.length - 1 ||
          isDisabled ||
          !memberHasInfo
            ? "-1"
            : "0"
        }
        key={member.seatNumber}
        id={member.seatNumber}
        className={`member-popup
                            ${
                              chosenMember === member.seatNumber &&
                              "member-popup--is-active"
                            }
                            ${
                              member.stemme !== "Fravaer" ? "" : "prevent-hover"
                            }
                            `}
        style={{ top: props.positionY + "%", left: props.positionX + "%" }}
      >
        <span
          className={`member-popup__dot ${
            member.stemme !== "Fravaer" ? "" : "prevent-hover"
          }`}
          style={{
            backgroundColor: member.dotColor,
            borderColor: member.strokeColor,
          }}
        ></span>
        <div
          className={`member-popup__data 
                        ${
                          props.positionX >= 50
                            ? "content-left-align"
                            : "content-right-align"
                        }
                        ${posClass}
                        ${
                          (member.seatNumber >= 171 &&
                            member.seatNumber <= 200) ||
                          leftCenter.includes(member.seatNumber)
                            ? "member-data-center"
                            : ""
                        }
                        ${
                          rightCenter.includes(member.seatNumber)
                            ? "member-data-off-center"
                            : ""
                        }
                        ${
                          arrayBelow.includes(member.seatNumber)
                            ? "member-data-below"
                            : ""
                        }
                        ${
                          arrayRight.includes(member.seatNumber)
                            ? "member-data-right"
                            : ""
                        }
                        ${
                          arrayLeft.includes(member.seatNumber)
                            ? "member-data-left"
                            : ""
                        }
                        ${
                          arrayBelowRight.includes(member.seatNumber)
                            ? "member-data-below-right"
                            : ""
                        }
                        ${
                          arrayBelowLeft.includes(member.seatNumber)
                            ? "member-data-below-left"
                            : ""
                        }`}
        >
          <span className="member_id">{member.seatNumber}</span>
          <span className="member_info">{memberTitle(member)}
          </span>
        </div>
      </div>
    );
  } else {
    return "";
  }
};

export default PrintlayoutChamberOverview;
