import React from "react";

const Modal = (props) => {
  function htmlDecode(input) {
    if (!input) return;
    return input.replace("&amp;lt;", "<").replace("&amp;gt;", ">");
  }

  const handleKeyDown = (event) => {
    if (event.key !== "Enter") event.preventDefault();
    if (event.key === "Enter") props.close(event);
  };

  if (!props.isVisible) {
    return null;
  }
  return (
    <div className="date-modal">
      <div className="date-modal-content">
        <h1>{props.data.title.title} </h1>
        <p
          dangerouslySetInnerHTML={{
            __html: htmlDecode(props.data.content.content),
          }}
        />
      </div>
      <div className="date-modal-actions">
        <button
          autoFocus
          className="date-modal-button"
          onClick={props.close}
          onKeyDown={handleKeyDown}
        >
          Ok
        </button>
      </div>
    </div>
  );
};

export default Modal;
