let apiUrl = null;
let siteId = null;
let dictionary = null;
let categories = null;
let captchaMetaData = null;
const youthParliamentPostElement = document.getElementById('youth-parliament-create-post');

const getApiUrl = () => {
    if(apiUrl === null) setApiUrl();
    return apiUrl;
}

const setApiUrl = () => {
    if(youthParliamentPostElement) apiUrl = youthParliamentPostElement.dataset.apiurl;  
}

const getSiteId = () => {
    if(siteId === null) setSiteId();
    return siteId;
}

const setSiteId = () => {
    if(youthParliamentPostElement) siteId = youthParliamentPostElement.dataset.siteid;  
}

const getDictionary = () => {
    if(dictionary === null) setDictionary();
    return dictionary;
}

const setDictionary = () => {
    if(youthParliamentPostElement) dictionary = JSON.parse(youthParliamentPostElement.dataset.dictionary);  
}

const getCategories = () => {
  if(categories == null) setCategories();
  return categories;
}

const setCategories = () => {
  if(youthParliamentPostElement) categories = JSON.parse(youthParliamentPostElement.dataset.categories);
}

const getCaptchaMetaData = () => {
    if(captchaMetaData === null) setCaptchaMetaData();
    return captchaMetaData;
  }
  
  const setCaptchaMetaData = () => {
    if(youthParliamentPostElement) captchaMetaData = JSON.parse(youthParliamentPostElement.dataset.captchametadata)
  }

export { getApiUrl, getSiteId, getDictionary, getCategories, getCaptchaMetaData }