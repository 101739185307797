
import CreatePostContent from "../components/create-post-content/create-post-content.component";
import ButtonNext from "../components/button-next/button-next.component";
import { useUpdateEffect } from "../../../ChamberOverview/shared/customHooks";

const FormStep1 = (props) => {
  const {
    register,
    unregister,
    errors,
    dictionary,
    setValue,
    croppedImage,
    setCroppedImage,
    videoSrc,
    watch,
    setPostTypeId,
    loadStepTwo,
    isFirstFormStepValid,
    setVideoSrc,
    formStep,
    handleFirstStepValidation
  } = props;

  const watchTitle = watch("title");
  const watchText = watch("text");
  const watchCategory = watch("category");

  useUpdateEffect(() => {
    if (formStep === 1) handleFirstStepValidation();
  }, [
    watchTitle,
    watchText,
    watchCategory,
    errors.category,
    errors.title,
    errors.text,
    formStep,
  ]);

  return (
    <div className="forum-create-post__content">
      <CreatePostContent
        register={register}
        unregister={unregister}
        errors={errors}
        dictionary={dictionary}
        setFormValue={setValue}
        croppedImage={croppedImage}
        setCroppedImage={setCroppedImage}
        videoSrc={videoSrc}
        setVideoSrc={setVideoSrc}
        watch={watch}
        watchCategory={watchCategory}
        setPostTypeId={setPostTypeId}
      />
      <div className="forum-create-post__buttons">
        <ButtonNext
          onStepChange={loadStepTwo}
          isValid={isFirstFormStepValid}
          dictionary={dictionary}
        />
      </div>
    </div>
  );
};

export default FormStep1;
