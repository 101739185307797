import React from "react";

import CheckboxGroup from "../checkbox-group/checkobx-group.component";
import SelectDropdown from "../select-dropdown/select-dropdown.component";
import { Controller } from "react-hook-form";

const CreatePostPersonalDetails = (props) => {
  const { register, errors, control, dictionary } = props;

  const classLevelOptions = [
    {
      id: "eighthClass",
      label: dictionary.Labels.ClassLevelOptionOne,
      value: "8",
    },
    {
      id: "ninthClass",
      label: dictionary.Labels.ClassLevelOptionTwo,
      value: "9",
    },
    {
      id: "tenthClass",
      label: dictionary.Labels.ClassLevelOptionThree,
      value: "10",
    },
  ];

  return (
    <div className="forum-create-post__personal-details">
      {/* school's dropdown */}
      <div className="forum-create-post__input-group">
        <div className="forum-input-row row">
          <div className="forum-input-col col-sm-12">
            <p>
              {dictionary.Labels.SchoolLabel}
            </p>
          </div>
        </div>
        <div className="forum-input-row row mb">
          <div className="forum-input-col col-md-12">
            <Controller
              name="school"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: dictionary.Errors.Required.School,
                },
              }}
              render={({ field: { onChange, name } }) => (
                <SelectDropdown
                  errors={errors}
                  name={name}
                  dictionary={dictionary}
                  triggerChange={onChange}
                />
              )}
            />
          </div>
        </div>

          <CheckboxGroup
            dictionary={dictionary}
            register={register}
            errors={errors}
            name="holdClass"
            classLevelOptions={classLevelOptions}
          />
      </div>

      {/* First student */}
      <div className="forum-create-post__input-group">
        <div className="forum-input-row row">
          <div className="forum-input-col col-sm-12">
            <p>{dictionary.Labels.ContactInfoStudentOne}</p>
          </div>
        </div>
        <div className="forum-input-row row">
          <div className="forum-input-col col-sm-6">
            <input
              className={errors.firstName1 ? "input--error" : ""}
              {...register("firstName1", {
                required: {
                  value: true,
                  message: dictionary.Errors.Required.Firstname,
                },
              })}
              type="text"
              aria-label={dictionary.Placeholders.Firstname}
              placeholder={`${dictionary.Placeholders.Firstname} *`}
            />
            {errors.firstName1 && (
              <div className="input-error-message">
                {errors.firstName1.message}
              </div>
            )}
          </div>
          <div className="forum-input-col col-sm-6">
            <input
              className={errors.lastName1 ? "input--error" : ""}
              {...register("lastName1", {
                required: {
                  value: true,
                  message: dictionary.Errors.Required.Lastname,
                  maxLength: 100,
                },
              })}
              type="text"
              aria-label={dictionary.Placeholders.Lastname}
              placeholder={`${dictionary.Placeholders.Lastname} *`}
            />
            {errors.lastName1 && (
              <div className="input-error-message">
                {errors.lastName1.message}
              </div>
            )}
          </div>
        </div>
        <div className="forum-input-row row">
          <div className="forum-input-col col-sm-6">
            <input
              className={errors.email1 ? "input--error" : ""}
              {...register("email1", {
                required: {
                  value: true,
                  message: dictionary.Errors.Required.Email,
                  minLength: 8,
                  maxLength: 8,
                },
                pattern: {
                  value:
                    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  message: dictionary.Errors.Email,
                },
              })}
              type="email"
              aria-label={dictionary.Placeholders.ContactEmail}
              placeholder={`${dictionary.Placeholders.ContactEmail} *`}
            />
            {errors.email1 && (
              <div className="input-error-message">{errors.email1.message}</div>
            )}
          </div>
        </div>
      </div>

      {/* Second student */}
      <div className="forum-create-post__input-group">
        <div className="forum-input-row row">
          <div className="forum-input-col col-sm-12">
            <p>{dictionary.Labels.ContactInfoStudentTwo}</p>
          </div>
        </div>
        <div className="forum-input-row row">
          <div className="forum-input-col col-sm-6">
            <input
              className={errors.firstName2 ? "input--error" : ""}
              {...register("firstName2", {
                required: {
                  value: true,
                  message: dictionary.Errors.Required.Firstname,
                },
              })}
              type="text"
              aria-label={dictionary.Placeholders.Firstname}
              placeholder={`${dictionary.Placeholders.Firstname} *`}
            />
            {errors.firstName2 && (
              <div className="input-error-message">
                {errors.firstName2.message}
              </div>
            )}
          </div>
          <div className="forum-input-col col-sm-6">
            <input
              className={errors.lastName2 ? "input--error" : ""}
              {...register("lastName2", {
                required: {
                  value: true,
                  message: dictionary.Errors.Required.Lastname,
                  maxLength: 100,
                },
              })}
              type="text"
              aria-label={dictionary.Placeholders.Lastname}
              placeholder={`${dictionary.Placeholders.Lastname} *`}
            />
            {errors.lastName2 && (
              <div className="input-error-message">
                {errors.lastName2.message}
              </div>
            )}
          </div>
        </div>
        <div className="forum-input-row row">
          <div className="forum-input-col col-sm-6">
            <input
              className={errors.email2 ? "input--error" : ""}
              {...register("email2", {
                required: {
                  value: true,
                  message: dictionary.Errors.Required.Email,
                  minLength: 8,
                  maxLength: 8,
                },
                pattern: {
                  value:
                    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  message: dictionary.Errors.Email,
                },
              })}
              type="email"
              aria-label={dictionary.Placeholders.ContactEmail}
              placeholder={`${dictionary.Placeholders.ContactEmail} *`}
            />
            {errors.email2 && (
              <div className="input-error-message">{errors.email2.message}</div>
            )}
          </div>
        </div>
      </div>

      {/* Third student */}
      <div className="forum-create-post__input-group">
        <div className="forum-input-row row">
          <div className="forum-input-col col-sm-12">
            <p>{dictionary.Labels.ContactInfoStudentThree}</p>
          </div>
        </div>
        <div className="forum-input-row row">
          <div className="forum-input-col col-sm-6">
            <input
              className={errors.firstName3 ? "input--error" : ""}
              type="text"
              aria-label={dictionary.Placeholders.Firstname}
              placeholder={dictionary.Placeholders.Firstname}
              {...register("firstName3")}
            />
          </div>
          <div className="forum-input-col col-sm-6">
            <input
              className={errors.lastname3 ? "input--error" : ""}
              type="text"
              aria-label={dictionary.Placeholders.Lastname}
              placeholder={dictionary.Placeholders.Lastname}
              {...register("lastName3")}
            />
          </div>
        </div>
        <div className="forum-input-row row">
          <div className="forum-input-col col-sm-6">
            <input
              className={errors.email3 ? "input--error" : ""}
              {...register("email3", {
                pattern: {
                  value:
                    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  message: dictionary.Errors.Email,
                },
              })}
              type="email"
              aria-label={dictionary.Placeholders.ContactEmail}
              placeholder={dictionary.Placeholders.ContactEmail}
            />
            {errors.email3 && (
              <div className="input-error-message">{errors.email3.message}</div>
            )}
          </div>
        </div>
      </div>

      {/* Teacher */}
      <div className="forum-create-post__input-group">
        <div className="forum-input-row row">
          <div className="forum-input-col col-sm-12">
            <p>{dictionary.Labels.ContactInfoTeacher}</p>
          </div>
        </div>
        <div className="forum-input-row row">
          <div className="forum-input-col col-sm-6">
            <input
              className={errors.name ? "input--error" : ""}
              {...register("name", {
                required: {
                  value: true,
                  message: dictionary.Errors.Required.Firstname,
                },
              })}
              type="text"
              aria-label={dictionary.Placeholders.Firstname}
              placeholder={`${dictionary.Placeholders.Firstname} *`}
            />
            {errors.name && (
              <div className="input-error-message">{errors.name.message}</div>
            )}
          </div>
          <div className="forum-input-col col-sm-6">
            <input
              className={errors.lastname ? "input--error" : ""}
              {...register("lastname", {
                required: {
                  value: true,
                  message: dictionary.Errors.Required.Lastname,
                  maxLength: 100,
                },
              })}
              type="text"
              aria-label={dictionary.Placeholders.Lastname}
              placeholder={`${dictionary.Placeholders.Lastname} *`}
            />
            {errors.lastname && (
              <div className="input-error-message">
                {errors.lastname.message}
              </div>
            )}
          </div>
        </div>
        <div className="forum-input-row row">
          <div className="forum-input-col col-sm-6">
            <input
              className={errors.email ? "input--error" : ""}
              {...register("email", {
                required: {
                  value: true,
                  message: dictionary.Errors.Required.Email,
                  minLength: 8,
                  maxLength: 8,
                },
                pattern: {
                  value:
                    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  message: dictionary.Errors.Email,
                },
              })}
              type="email"
              aria-label={dictionary.Placeholders.ContactEmail}
              placeholder={`${dictionary.Placeholders.ContactEmail} *`}
            />
            {errors.email && (
              <div className="input-error-message">{errors.email.message}</div>
            )}
          </div>
        </div>
      </div>

      {/* Accept terms */}
      <div className="forum-create-post__input-group">
        <div className="forum-input-row row">
          <div className="forum-input-col col-sm-12">
            <label
              htmlFor="accept-terms"
              className={`checkbox__container accept-terms ${
                errors.terms ? "input--error" : ""
              }`}
            >
              <span
                className="check-label"
                dangerouslySetInnerHTML={{
                  __html: dictionary.Labels.AcceptTermsOfConditions,
                }}
              />
              <span className="custom-checkbox">
                <input
                  {...register("terms", {
                    required: {
                      value: true,
                      message: dictionary.Errors.AcceptTermsOfConditions,
                    },
                  })}
                  type="checkbox"
                  aria-label={dictionary.Labels.AcceptTermsOfConditions}
                  id="accept-terms"
                  tabIndex={0}
                />
                <span className="icon"></span>
              </span>
            </label>
            {errors.terms && (
              <div className="input-error-message">{errors.terms.message}</div>
            )}
          </div>
        </div>
        <div className="forum-input-row row">
          <div className="forum-input-col col-md-12">
            <p>{dictionary.Labels.RequiredFieldText}</p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CreatePostPersonalDetails;
