import { Route, Redirect } from "react-router-dom";

const ProtectedRoute = ({ component: Comp, isPathValid, path, ...props }) => {
  return (
    
    <Route path={path}>
      {isPathValid ? <Comp {...props} /> : <Redirect to={`/${props.dictionary.FormSteps.FormStepOnePath}`} />}
    </Route>
  );
};

export default ProtectedRoute;
