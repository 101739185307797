import React from "react";

const ErrorPage = (props) => {
  const { dictionary, loadStepOne, setIsSubmitFailed, errorCode } = props;
  const { GeneralError, AntivirusError } = dictionary.ErrorPage;
  const isAntivirusError = errorCode === 403;
  const errorTitle = isAntivirusError
    ? AntivirusError.Title
    : GeneralError.Title;
  const errorText = isAntivirusError ? AntivirusError.Text : GeneralError.Text;

  const handleOnChage = (event) => {
    event.preventDefault();
    setIsSubmitFailed(false);
    loadStepOne();
  };

  return (
    <div className="error-page">
      <h2 role="alert">{errorTitle}</h2>
      <p role="alert">{errorText}</p>
      <div className="error-page__button">
        <button
          type="button"
          onClick={handleOnChage}
          className="button button--nostyling button--back"
        >
          <span>{dictionary.ErrorPage.BackToForm}</span>
        </button>
      </div>
    </div>
  );
};

export default ErrorPage;
