import Routes from "./routes";
import { useGlobalState } from "state-pool";
import Loading from "./components/Loading";
import Seats from "./components/Seats";
import VoteResults from "./components/voteResults";
import Preview from "./containers/preview/preview.components";
import MemberDetailedVoteResult from "./components/MemberDetailedVoteResult/MemberDetailedVoteResult.component";
import * as helpers from "./shared/helpers";
import { useLayoutEffect } from "react";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";

export default function ChamberOverview() {
  const [appdata, setAppData] = useGlobalState("appdata");
  const [appdataLoading, setAppdataLoading] = useGlobalState("appdataLoading");
  const [chosenParties, setChosenParties, updateChosenParties] =
    useGlobalState("chosenParties");
  const [detailedMemberList, setDetailedMemberList] =
    useGlobalState("detailedMemberList");
  const [date, setDate] = useGlobalState("date");
  const [isDatepickerTriggered, setIsDatepickerTriggered] = useGlobalState(
    "isDatepickerTriggered"
  );

  if (!appdata && !appdataLoading) {
    setAppdataLoading(true);
    if (helpers.getAppType() === "preview") {
      const urlParams = new URLSearchParams(window.location.search);
      const parameterList = urlParams.get("parameterList");

      helpers.loadDataForPreview(parameterList).then((response) => {
        if (response.statusText === "success") {
          setAppData(response.data);
        }
        if (response.statusText === "error") {
          setAppData(response.statusText);
        }
        setAppdataLoading(false);
      });
    }
    if (helpers.getAppType() === "voteResult") {
      helpers.loadDataForVote().then((response) => {
        if (response.statusText === "success") {
          setAppData(response.data);
        }
        if (response.statusText === "error") {
          setAppData(response.statusText);
        }
        setAppdataLoading(false);
      });
    } else if (helpers.getAppType() === "chamberOverview") {
      // document.querySelector('.chamberOverview-full').previousElementSibling.querySelector('.top-searchbar').classList.add('show-field-chamber');
      // document.querySelector('.chamberOverview-full').previousElementSibling.querySelector('.icon-menu').classList.add('show-icon-chamber');
      helpers.loadDataForDate(date).then((response) => {
        setIsDatepickerTriggered(true);

        if (response.statusText === "success") {
          setAppData(response.data);
          if (response.data !== null) {
            const chosenPartiesParam = window.location.href.match(
              /(\bpartyFilter_\S+\b)/gi
            )
              ? window.location.href
                  .match(/(\bpartyFilter_\S+\b)/gi)[0]
                  .split("&")
                  .map(function (item) {
                    return item.split("=")[1];
                  })
              : [];

            const chosenPartyToPush = response.data.parties.party
              .filter(
                (party, index) =>
                  party.partyAbbreviation ===
                  helpers.getHashQueryStringByName("partyFilter_" + index)
              )
              .map(function (party) {
                return party.partyAbbreviation;
              });
            setChosenParties(chosenPartyToPush);
            if (!date) {
              setDate(response.data.date.searchDate.replace(/-/g, ""));
            }
            if (chosenPartyToPush.length > 0) {
              const membersToPush = response.data.seats.seat.filter((seat) =>
                chosenPartyToPush.includes(seat.partyAbbreviation)
              );

              membersToPush.sort(function (a, b) {
                return chosenPartiesParam.indexOf(a.partyAbbreviation) <
                  chosenPartiesParam.indexOf(b.partyAbbreviation)
                  ? -1
                  : 1;
              });
              setDetailedMemberList(membersToPush);
              setChosenParties(chosenPartiesParam);
              setIsDatepickerTriggered(false);
            }
          }
        }
        setAppdataLoading(false);
      });
    }
  }

  useLayoutEffect(() => {
    initEmailHref();
    handleBreadcrumClass();
    addEventForDownload();
    return () => {
      RemoveEventForDownload();
    };
  });

  const initEmailHref = () => {
    const urlToReplace = encodeURIComponent(
      `${window.location.protocol}//${window.location.hostname}${window.location.pathname}`
    ).toLowerCase();
    const emailElement = document.querySelector(".content-action-mail");
    if (!emailElement) return;
    let href = emailElement.getAttribute("href");
    emailElement.setAttribute(
      "href",
      href.replace(urlToReplace, encodeURIComponent(window.location.href))
    );
  };

  const voteResultNoDataCss = () => {
    const chamberOverviewElement = document.getElementById("chamber-overview");
    if (!chamberOverviewElement) return;
    chamberOverviewElement.classList.add("no-data");
  };

  const addEventForDownload = () => {
    const downloadMenuElement = document.querySelector(".js-download-page");
    if (!downloadMenuElement) return;
    downloadMenuElement.addEventListener("click", handleDownloadClick);
  };

  const RemoveEventForDownload = () => {
    const downloadMenuElement = document.querySelector(".js-download-page");
    if (!downloadMenuElement) return;
    downloadMenuElement.removeEventListener("click", handleDownloadClick);
  };

  const handleBreadcrumClass = () => {
    const element = document.querySelector(".breadcrumb-wrapper");
    if (!element) return;
    element.classList.remove("row");
    element.classList.add("container");
  };

  const handleDownloadClick = () => {
    const downloadOptions = {
      orientation: "l",
      format: "a3",
    };
    downloadPdfDocument("download-pdf", date, downloadOptions);
  };

  const downloadPdfDocument = (rootElementId, downloadFileName, options) => {
    const input = document.getElementById(rootElementId);
    if (!input) return;
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF(options);
      pdf.addImage(imgData, "JPEG", 50, 0);
      pdf.save(`${downloadFileName}.pdf`);
    });
  };

  if (helpers.getAppType() === "preview") {
    const htmlElement = document.querySelector("html");
    if (htmlElement) {
      htmlElement.classList.add("chamber-overview");
    }
    return appdata !== null ? (
      <div className="chamber-overview container">
        <Preview />
      </div>
    ) : (
      <Loading />
    );
  }

  if (helpers.getAppType() === "voteResult") {
    if (appdata === null) {
      return <Loading />;
    }
    if (appdata === "error") {
      voteResultNoDataCss();
      return <React.Fragment></React.Fragment>;
    }
    if (appdata !== null) {
      return (
        <div>
          <div className="voting__content">
            <VoteResults />
          </div>
          <Seats isVoteResult={true} />
          <MemberDetailedVoteResult />
        </div>
      );
    }
  } else if (helpers.getAppType() === "chamberOverview") {
    const htmlElement = document.querySelector("html");
    if (htmlElement) {
      htmlElement.classList.add("chamber-overview");
    }
    return appdata !== null ? (
      <div className="chamber-overview container">
        <Routes />
      </div>
    ) : (
      <Loading />
    );
  }
}
