import React, { useEffect, useState, useLayoutEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  HashRouter as Router,
  Switch,
  Route,
  NavLink,
  useRouteMatch,
} from "react-router-dom";
import { Redirect } from "react-router-dom";
import Intro from "../components/Intro";
import FilterDropdown from "../components/FilterDropdown";
import DatePicker from "../components/Date_picker";
import Parties from "../components/Parties";
import Seats from "../components/Seats";
import MemberList from "../components/MemberList";
import Reset from "../components/Reset_button";
import Loading from "../components/Loading";
import FacetedFilters from "../components/FacetedFilters";
import Modal from "../components/Modal";
import { useGlobalState } from "state-pool";
import * as helpers from "../shared/helpers";

export default function Overview(props) {
  const history = useHistory();
  const location = useLocation();
  const [appdata, setAppData] = useGlobalState("appdata");
  const [date, setDate] = useGlobalState("date");
  let { path, url } = useRouteMatch();
  const [memberFilter, setMemberFilter] = useGlobalState("memberFilter");
  const [committeeFilter, setCommitteeFilter] =
    useGlobalState("committeeFilter");
  const [constituencyFilter, setConstituencyFilter] =
    useGlobalState("constituencyFilter");
  const [ministerialTitleFilter, setMinisterialTitleFilter] = useGlobalState(
    "ministerialTitleFilter"
  );
  const [rapporteurshipFilter, setRapporteurshipFilter] = useGlobalState(
    "rapporteurshipFilter"
  );
  const desktopViewSize = 900;
  const [previousDropdownId, setPreviousDropdownId] = useState("");
  const [isDesktopView, setIsDesktopView] = useState(window.innerWidth > desktopViewSize);
  const $ = window.$;

  //    setMemberFilter(helpers.getHashQueryStringByName('memberFilter') || 0);
  //    setCommitteeFilter(helpers.getHashQueryStringByName('committeeFilter') || 0);
  //    setConstituencyFilter(helpers.getHashQueryStringByName('constituencyFilter') || 0);
  //    setMinisterialTitleFilter(helpers.getHashQueryStringByName('ministerialTitleFilter') || 0);
  //    setRapporteurshipFilter(helpers.getHashQueryStringByName('rapporteurshipFilter') || 0);

  const title = useGlobalState("overviewTitle");
  const manchet = useGlobalState("overviewManchet");

  const onDateChange = (selecteddate) => {
    if (date !== selecteddate) {
      setDate(selecteddate);
      setAppData(null);

      if (!appdata) {
        helpers.loadDataForDate(selecteddate).then((response) => {
          if (response.statusText === "success") {
            setAppData(response.data);
          } else {
            alert("Noget gik galt");
          }
        });
      }
    }
  };

  useEffect(() => {
    props.setEmailHref();
  }, [history.location, history.location.search]);

  const handleDateQuery = (queryDate) => {
    history.push({
      search: helpers.updateUrlParameter(
        history.location.search,
        "date",
        queryDate.replace(/-/g, "")
      ),
    });
  };

  const handleCloseModal = (e) => {
    e.preventDefault();
    props.modalDependencies.setIsModalVisible(false);
    handleDateQuery(appdata.date.searchDate);
    setDate(appdata.date.searchDate.replace(/-/g, ""));
    $("#content").removeClass("noScroll");
  };

  useLayoutEffect(() => {
    document.title = props.overviewDocumentTitle;
    removeDataVisualizationClass();
    setupSecondaryServiceMenuPrint();
    window.addEventListener("resize", handleWindowSizeChange);
    return () => window.removeEventListener("resize", handleWindowSizeChange);
  });

  useEffect(() => {
    handleDateQuery(appdata.date.searchDate);
  }, []);

  const setupSecondaryServiceMenuPrint = () => {
    const dataPrintElement = document.getElementsByClassName('data-print')[0];
    const overviewPrintElement = document.getElementsByClassName('overview-print')[0];

    if(dataPrintElement){
      dataPrintElement.style.display = "none";
    }
    if(overviewPrintElement){
      overviewPrintElement.style.display = "inline";    
    }
  }

  const handleWindowSizeChange = () => {
    setIsDesktopView(window.innerWidth > desktopViewSize);
  };

  const removeDataVisualizationClass = () => {
    const bodyElement = document.querySelector("body");
    if (bodyElement) {
      bodyElement.classList.remove("data-visualization-route");
    }
  };

  const renderFilters = (e) => {
    if (e.keyCode && e.keyCode !== 13) {
      return;
    } else {
      $("#content").scrollTop(0);
      $(".mobile-view").slideDown("fast");
      $("#content").toggleClass("noScroll");
    }
  };

  const hideFilters = (e) => {
    if (e.keyCode && e.keyCode !== 13) {
      return;
    } else {
      $(".mobile-view").slideUp("fast");
      $("#content").toggleClass("noScroll");
    }
  };

  if (appdata === null) {
    return (
      <div className="container">
        <Loading />
      </div>
    );
  } else if (appdata) {
    const PrintDate =
      appdata.date.searchDate.substring(8, 10) +
      "-" +
      appdata.date.searchDate.substring(5, 7) +
      "-" +
      appdata.date.searchDate.substring(0, 4);
    return (
      <div className="container container-overview">
        <div
          id="overlay"
          className={`page-overlay ${
            props.modalDependencies.isModalVisible && "show-overlay"
          } `}
        ></div>
        <Modal
          close={handleCloseModal}
          isVisible={props.modalDependencies.isModalVisible}
          data={appdata.popup}
        />
        <div className="chamber-overview-print-date">{PrintDate}</div>
        <div className="row intro-container overview">
          <div className="col-sm-8">
            <Intro title={title[0]} description={manchet[0]} />
          </div>
        </div>
        <div className="row chamber-overview__filters-btn--container">
          <a
            role="button"
            className="chamber-overview__filters-btn"
            tabIndex={0}
            onClick={renderFilters}
            onKeyUp={renderFilters}
          >
            <i className="icon-ft_filter" aria-hidden="true"></i>
            <p className="visually-hidden">Filtrer din visning</p>
          </a>
          <p>Filtrer din visning</p>
        </div>
        <div className="contentProps">
          <div className="mobile-view">
            <div className="row">
              <a
                role="button"
                className="chamber-overview__filters--close-btn"
                tabIndex={0}
                onClick={hideFilters}
                onKeyUp={hideFilters}
              >
                <span
                  className="icon-ft_accordionplus"
                  aria-hidden="true"
                ></span>
                <p className="visually-hidden">Luk</p>
              </a>
            </div>
            <div className="faceted-filters-overlay">
              <FacetedFilters />
            </div>
            <div className="chamber-overview__filters row">
              <div className="col-sm-12 col-md-4">
                <FilterDropdown
                  data={appdata.members.member}
                  noSelection={appdata.members.noSelection}
                  label={appdata.members.label}
                  seats={appdata.seats.seat}
                  valueKey="personId"
                  labelKey="fullName"
                  filterKey="personId"
                  filterName="memberFilter"
                  defaultValue={memberFilter.value}
                  previousDropdownId={previousDropdownId}
                  setPreviousDropdownId={setPreviousDropdownId}
                />
              </div>
              <div className="col-sm-12 col-md-4">
                <FilterDropdown
                  data={appdata.committees.committee}
                  noSelection={appdata.committees.noSelection}
                  label={appdata.committees.label}
                  seats={appdata.seats.seat}
                  valueKey="committeeAbbreviation"
                  labelKey="committeeName"
                  filterKey="committeeAbbreviation"
                  filterName="committeeFilter"
                  defaultValue={committeeFilter.value}
                  previousDropdownId={previousDropdownId}
                  setPreviousDropdownId={setPreviousDropdownId}
                />
              </div>
              <div className="col-sm-12 col-md-4">
                <FilterDropdown
                  data={appdata.constituencies.constituency}
                  noSelection={appdata.constituencies.noSelection}
                  label={appdata.constituencies.label}
                  seats={appdata.seats.seat}
                  valueKey="constituencyName"
                  labelKey="constituencyName"
                  filterKey="constituencyName"
                  filterName="constituencyFilter"
                  defaultValue={constituencyFilter.value}
                  previousDropdownId={previousDropdownId}
                  setPreviousDropdownId={setPreviousDropdownId}
                />
              </div>
            </div>
            <div className="chamber-overview__filters row">
              <div className="col-sm-12 col-md-4">
                <FilterDropdown
                  data={appdata.ministerialTitles.ministerialPost}
                  noSelection={appdata.ministerialTitles.noSelection}
                  allSeelction={appdata.ministerialTitles.allSeelction}
                  label={appdata.ministerialTitles.label}
                  seats={appdata.seats.seat}
                  valueKey="personId"
                  labelKey="ministerTitleAndPerson"
                  filterKey="personId"
                  filterName="ministerialTitleFilter"
                  defaultValue={ministerialTitleFilter.value}
                  previousDropdownId={previousDropdownId}
                  setPreviousDropdownId={setPreviousDropdownId}
                />
              </div>
              <div className="col-sm-12 col-md-4">
                <FilterDropdown
                  data={appdata.spokesmen.spokesman}
                  noSelection={appdata.spokesmen.noSelection}
                  label={appdata.spokesmen.label}
                  seats={appdata.seats.seat}
                  valueKey="rapporteurship"
                  labelKey="rapporteurship"
                  filterKey="name"
                  filterName="rapporteurshipFilter"
                  defaultValue={rapporteurshipFilter.value}
                  previousDropdownId={previousDropdownId}
                  setPreviousDropdownId={setPreviousDropdownId}
                />
              </div>
              {isDesktopView && (
                <div className="col-sm-12 col-md-4 mobile-hidden">
                  <DatePicker
                    label={appdata.dateSelection.label}
                    defaultDate={appdata.date.searchDate}
                    maxDate={appdata.dateSelection.maxDate}
                    minDate={appdata.dateSelection.minDate}
                    date={date}
                    setDate={onDateChange}
                    nonDates={appdata.nonSelectableDates.nonDates}
                    dateFeedbackLabels={appdata.dateFeedbackLabels}
                  />
                </div>
              )}
            </div>
            <div className="row chamber-overview__filters--filter__container">
              <a
                role="button"
                className="chamber-overview__filters--filter"
                tabIndex={0}
                onClick={hideFilters}
                onKeyUp={hideFilters}
              >
                Anvend
              </a>
            </div>
          </div>
          {!isDesktopView && (
            <div className="row mobile-view-date-picker">
              <div className="col-xs-12 col-sm-6 overview-input-mobile">
                <DatePicker
                  label={appdata.dateSelection.label}
                  defaultDate={appdata.date.searchDate}
                  maxDate={appdata.dateSelection.maxDate}
                  minDate={appdata.dateSelection.minDate}
                  date={date}
                  setDate={onDateChange}
                  nonDates={appdata.nonSelectableDates.nonDates}
                  dateFeedbackLabels={appdata.dateFeedbackLabels}
                />
              </div>
            </div>
          )}

          <div id="download-pdf" className="row render-seats-parties">
            <div className="col-xs-12 col-md-2 render-down no-space parties-container">
              <Parties parties={appdata.parties.party} />
            </div>
            <div className="col-xs-12 col-md-9 no-space seats-container">
              <FacetedFilters />
              <Seats />
            </div>
            <div className="col-md-1 render-seats-reset">
              <Reset data={appdata} />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <MemberList />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
