import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";

const Dropzone = ({ handleImageChange, dictionary, imageAccept }) => {
  const onDrop = useCallback((acceptedFiles) => {
    handleImageChange(acceptedFiles[0]);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: 1,
  });

  return (
    <div className="file-modal__dropzone">
      <div className="dropzone-button" {...getRootProps()}>
        <div className="dropzone-button-svg"></div>
        <p className="dropzone-text">{dictionary.Modal.Upload.DropZone}</p>
        <p>{dictionary.Modal.Upload.MidZoneText}</p>
        <input
          {...getInputProps()}
          aria-label={dictionary.Modal.Upload.DropZone}
          accept={imageAccept}
        />
      </div>
    </div>
  );
};

export default Dropzone;
