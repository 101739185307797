import React from "react";
import { useGlobalState } from "state-pool";
import MemberDetailedDataVisualization from "./MemberDetailedDataVisualization.jsx";

export default function MemberListDataVisualization(props) {
  const [dataVisualizationDetailedMemberList] = useGlobalState(
    "dataVisualizationDetailedMemberList"
  );

  return (
    <div>
      <div className="members contentProps">
        {dataVisualizationDetailedMemberList.map((item, index) => (
          <MemberDetailedDataVisualization key={index} member={item} />
        ))}
      </div>
    </div>
  );
}
