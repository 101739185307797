import axios from "axios";

let apiUrl = null;
let appType = null;
export function getApiUrl(url) {
  return apiUrl;
}
export function setApiUrl(url) {
  apiUrl = url;
}
export function setAppType(type) {
  appType = type;
}
export function getAppType() {
  return appType;
}
export const getHashQueryStringByName = (name) => {
  let url = window.location.href;
  url = url.split("#")[1];
  if (!url) return null;
  url = url.split("?")[1];
  if (!url) return null;
  const result = url.split("&");
  let returnVal = null;
  if (result) {
    result.forEach((param) => {
      var [key, value] = param.split("=");
      if (key === name && value) {
        returnVal = decodeURI(value);
      }
    });
  }
  return returnVal;
};
export const createHashQueryString = (name, value) => {
  const params = new URLSearchParams();
  params.append(name, value);
  return params.toString();
};
export const updateUrlParameter = function (uri, key, value) {
  // remove the hash part before operating on the uri
  var i = uri.indexOf("#");
  var hash = i === -1 ? "" : uri.substr(i);
  uri = i === -1 ? uri : uri.substr(0, i);

  var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
  var separator = uri.indexOf("?") !== -1 ? "&" : "?";

  if (!value) {
    // remove key-value pair if value is empty
    uri = uri.replace(new RegExp("([?&]?)" + key + "=[^&]*", "i"), "");
    if (uri.slice(-1) === "?") {
      uri = uri.slice(0, -1);
    }
    // replace first occurrence of & by ? if no ? is present
    if (uri.indexOf("?") === -1) uri = uri.replace(/&/, "?");
  } else if (uri.match(re)) {
    uri = uri.replace(re, "$1" + key + "=" + value + "$2");
  } else {
    uri = uri + separator + key + "=" + value;
  }
  return uri + hash;
};
export const convertDate = (date) => {
  var startDate = date;
  var displayDate = "";

  var year = startDate.substring(0, 4);
  var month = startDate.substring(4, 6);
  var day = startDate.substring(6, 8);

  displayDate = year + "-" + month + "-" + day;
  return displayDate;
};
export const today = () => {
  var x = new Date();
  var y = x.getFullYear().toString();
  var m = (x.getMonth() + 1).toString();
  var d = x.getDate().toString();
  d.length === 1 && (d = "0" + d);
  m.length === 1 && (m = "0" + m);
  var yyyymmdd = y + m + d;
  return yyyymmdd;
};
export const updateChartColumns = () => {
  const elements = document.querySelectorAll(".chart__party-group");
  elements.forEach((element) => {
    if (element.childNodes.length > 15) {
      element.style.width = "auto";
      element.style.width = `${element.scrollWidth + 3}px`;
    }
  });
};
export const loadDataForVote = async () => {
  if (apiUrl) {
    return axios
      .get(apiUrl)
      .then((res) => {
        if (res.data.voteResult !== undefined) {
          return {
            data: res.data.voteResult,
            statusText: "success",
          };
        } else {
          return {
            data: null,
            statusText: "error",
          };
        }
      })
      .catch((error) => {
        return error;
      });
  }
};
export const loadDataForDate = async (date) => {
  const dateParam = date ? "date=" + date : "";
  if (apiUrl) {
    return axios
      .get(apiUrl + dateParam)
      .then((res) => {
        if (res.data.plenaryData !== undefined) {
          return {
            data: res.data.plenaryData,
            statusText: "success",
          };
        } else {
          return {
            data: null,
            statusText: "error",
          };
        }
      })
      .catch((error) => {
        return error;
      });
  }
};
export const loadDataForPreview = async (parameterList) => {
  if (apiUrl) {
    return axios
      .get(apiUrl + parameterList)
      .then((res) => {
        if (res.data.plenaryData !== undefined) {
          return {
            data: res.data.plenaryData,
            statusText: "success",
          };
        } else {
          return {
            data: null,
            statusText: "error",
          };
        }
      })
      .catch((error) => {
        return error;
      });
  }
};
export const findKeyValueRecursively = function (
  input,
  keyOfTheValue,
  valueToFind
) {
  //Some validation
  if (typeof keyOfTheValue !== "string") {
    throw new Error("Invalid parameter: keyOfTheValue has to be a string.");
  }

  //Accepts arrays of objects.
  if (Array.isArray(input)) {
    return input.reduce(function (result, element) {
      if (result === true) {
        return true;
      }

      //Recursive call.
      return findKeyValueRecursively(element, keyOfTheValue, valueToFind);
    }, false);
  }

  //Process objects.
  if (input !== null && typeof input === "object") {
    //IMPORTANT: typeof null === 'object', this is a known JavaScript bug [1].

    if (keyOfTheValue in input && input[keyOfTheValue] === valueToFind) {
      //See [2].
      //We found it!
      return true;
    }

    //Check for other nested objects or arrays.
    for (let key in input) {
      //The hasOwnProperty function is used to exclude properties found in the prototype chain.
      if (input.hasOwnProperty(key)) {
        //Recursive call: iterates through all the object properties.
        if (findKeyValueRecursively(input[key], keyOfTheValue, valueToFind)) {
          return true;
        }
      }
    }
  }

  return false;
};
